<template>
  <v-app>
    <v-app-bar app elevate-on-scroll :color="app_bar_color">
      <v-app-bar-nav-icon
        class="hidden-sm-and-up"
        @click="sidebar = !sidebar"
      />
      <v-img
        contain
        max-height="60"
        :max-width="widthLogo"
        :src="logo"
        position="left"
        @click="gotoHome"
      >
      </v-img>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <div class="mr-5 mr-md-5 d-inline-flex hidden-xs-only">
        <v-btn
          text
          :color="menuColor"
          class="hidden-xs-only"
          @click="$vuetify.goTo('#heading-2')"
        >
          <small>Nosotros</small>
        </v-btn>
        <v-btn
          text
          :color="menuColor"
          class="hidden-xs-only"
          @click="$vuetify.goTo('#heading-capacitaciones')"
        >
          <small>Capacitaciones</small>
        </v-btn>
        <v-btn
          text
          :color="menuColor"
          class="hidden-xs-only"
          @click="$vuetify.goTo('#heading-6')"
        >
          <small>Contrato</small>
        </v-btn>
        <v-btn
          text
          :color="menuColor"
          class="hidden-xs-only"
          @click="$vuetify.goTo('#heading-7')"
        >
          <small>Contactanos</small>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="sidebar" app dark color="my_battery_blue">
      <v-list dense nav expand>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="handleNavigation(item)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main v-if="dialog">
      <dialog-password v-if="dialog" @close="handleDialog" />
    </v-main>
    <v-main v-else>
      <v-row id="heading-1" class="px-xl-16 mx-xl-16 f-h pb-0">
        <v-col
          cols="12"
          md="6"
          align="center"
          class="d-flex align-center flex-column justify-center"
        >
          <div
            class="text-left pt-16"
            :style="
              $vuetify.breakpoint.xs ? 'max-width: 90%' : 'max-width: 70%'
            "
          >
            <h1 class="primary--text text-lg-h3 text-md-h4 font-weight-bold">
              Formemos una alianza estratégica que genere ganancias para ambas
              partes.
            </h1>
            <v-divider class="my_divider my_yellow mt-4" />
            <div class="text-left py-10">
              <p class="support--text font-weight-medium">
                Como distribuidor de nuestro software podrás obtener beneficios
                de forma recurrente.
              </p>
            </div>
            <v-btn
              large
              color="primary"
              elevation="0"
              @click="$vuetify.goTo('#heading-6')"
            >
              contactanos
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pb-0 bg-equipo d-flex flex-column justify-end"
          align="center"
        >
          <div class="pb-0 mb-0">
            <v-img contain max-height="200" :src="'/img/clouds/cloud2.png'" />
          </div>
        </v-col>
      </v-row>
      <v-row id="heading-2" class="pt-0 mb-16 py-16">
        <v-col
          cols="12"
          md="8"
          order="1"
          order-md="1"
          class="pt-5 offset-md-2 pb-md-16"
          align="center"
        >
          <div class="pt-15">
            <h1 class="primary--text text-lg-h4 text-md-h5 font-weight-bold">
              Descubre un poco más sobre nosotros
            </h1>
            <v-divider class="my_divider my_yellow mt-3" />
          </div>
        </v-col>
        <v-col
          cols="12"
          md="7"
          lg="8"
          order="3"
          order-md="2"
          class="px-md-16 px-4"
          align="center"
        >
          <div class="position_relative">
            <v-card
              max-width="600"
              flat
              style="z-index: 3"
              class="px-4 px-md-0"
            >
              <div class="fl360-video-container pa-0 ma-0">
                <video style="width: 100%; height: 300px" controls>
                  <source
                    src="https://mynube-landingpage.s3.us-west-2.amazonaws.com/videos/lp_mynube_vd_1.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </v-card>
            <div class="linea_vid_inst" style="z-index: 2">
              <v-img
                contain
                max-height="150"
                :src="'img/parcials/distribuidores/videoInstitucional/lineas_vid_inst.png'"
              />
            </div>
            <div class="linea_vid_inst2" style="z-index: 2">
              <v-img
                contain
                max-height="150"
                :src="'img/parcials/distribuidores/videoInstitucional/lineas_vid_inst_2.png'"
              />
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          lg="4"
          order="2"
          order-md="3"
          align="center"
          class="pr-lg-16 pb-16 pb-md-0"
        >
          <div class="position_relative mt-5 mb-16">
            <div class="my_soft_blue video_instuticional_back"></div>
            <v-card
              flat
              color="my_grey"
              class="px-10 pb-8"
              :max-width="$vuetify.breakpoint.mdAndDown ? 290 : 350"
            >
              <v-row>
                <v-col cols="12" align="center">
                  <i
                    :style="
                      $vuetify.breakpoint.lgAndUp
                        ? 'font-size: 4.5rem'
                        : 'font-size: 3rem'
                    "
                    :class="['bx bx-building', 'primary--text pt-5']"
                  ></i>
                  <h3 class="pt-4 primary--text">Video Institucional</h3>
                </v-col>
                <v-col cols="12" class="text-left">
                  <small>
                    Es un gusto compartirte este breve video donde podrás
                    conocer un poco mejor a nuestra empresa, sus objetivos y el
                    equipo de trabajo que la conforma.
                  </small>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row
        id="heading-3"
        class="my_battery_blue pb-10 pb-md-10 pb-lg-1"
        align="center"
        justify="center"
      >
        <v-col cols="12" md="5" lg="4" align="center" class="pl-lg-16">
          <div class="position_relative mt-16">
            <div class="my_yellow presentacion_back"></div>
            <v-card
              flat
              color="my_grey"
              class="px-10 pb-8"
              :max-width="$vuetify.breakpoint.mdAndDown ? 290 : 350"
            >
              <v-row>
                <v-col cols="12" align="center">
                  <i
                    :style="
                      $vuetify.breakpoint.lgAndUp
                        ? 'font-size: 4.5rem'
                        : 'font-size: 3rem'
                    "
                    :class="['bx bx-slideshow', 'primary--text pt-5']"
                  ></i>
                  <h3 class="pt-4 primary--text">
                    Te presentamos nuestra oferta
                  </h3>
                </v-col>
                <v-col cols="12" class="text-left">
                  <small>
                    Conoce el detalle de nuestra oferta para tus clientes, que
                    beneficios obtienes, como funciona mynube, precios,
                    implementación y soporte.
                  </small>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="7"
          lg="8"
          class="px-0 mx-0 my-md-2 my-lg-5 px-lg-16"
          align="center"
        >
          <div class="video_pres mt-16 my-lg-16">
            <div class="video_pres_cont">
              <v-card max-width="800" flat style="z-index: 3" class="">
                <div class="pa-0 ma-0">
                  <video controls class="video-source" id="video_presentacion">
                    <source
                      src="https://mynube-landingpage.s3.us-west-2.amazonaws.com/videos/lp_mynube_vd2.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </v-card>
              <!--              <iframe
                id="video_presentacion"
                src=""
                frameborder="0"
                title=""
                allow=""
                allowfullscreen
                sandbox
              ></iframe>-->
            </div>
          </div>
        </v-col>
      </v-row>
      <galeria-equipo />
      <v-row id="heading-4" class="px-4 px-md-0">
        <v-col cols="12" md="8" class="pt-5 offset-md-2" align="center">
          <div class="pt-15">
            <h1 class="primary--text text-lg-h4 text-md-h5 font-weight-bold">
              Visualiza el demo del sistema
            </h1>
            <v-divider class="my_divider my_yellow mt-3" />
            <div class="text-left pt-10 px-4 px-md-5 px-lg-15">
              <p
                class="support--text font-weight-medium"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                El software mynube ERP tiene una variedad de módulos que se
                adaptan a las necesidades administrativas de cada empresa en
                cualquier tipo de negocio. Te invitamos a ver el vídeo que
                presenta nuestro sistema.
              </p>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          lg="7"
          class="offset-md-2 offset-lg-0 px-md-16 px-4"
          align=""
        >
          <div class="video_demo my-lg-16">
            <div class="video_demo_cont">
              <iframe
                src="https://www.youtube.com/embed/euZeC9oSExQ?si=k-HLlGCVlzP2NvK_"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="8"
          lg="5"
          align="center"
          class="offset-md-2 offset-lg-0 pr-lg-16 my-lg-16"
        >
          <h2
            class="primary--text text-lg-h5 text-md-h6 font-weight-bold pt-lg-16"
          >
            Algunas carateristicas de mynube
          </h2>
          <v-row
            no-gutters
            class="px-md-5 pt-15 pb-5 text-md-h6 support--text caracteristicas_list"
          >
            <v-col
              cols="12"
              md="6"
              align="left"
              class="pt-0 pt-md-1 no-gutters"
            >
              <ul>
                <li class="py-1">Clientes</li>
                <li class="py-1">Ventas</li>
                <li class="py-1">Cotizaciones</li>
                <li class="py-1">Compras</li>
                <li class="py-1">Proveedores</li>
              </ul>
            </v-col>
            <v-col
              cols="12"
              md="6"
              align="left"
              class="pt-0 pt-md-1 pl-md-15 pl-lg-1 no-gutters"
            >
              <ul>
                <li class="py-1">Inventarios</li>
                <li class="py-1">Contabilidad</li>
                <li class="py-1">Reporteria</li>
                <li class="py-1">Finanzas</li>
                <li class="py-1">Recursos humanos</li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row id="heading-capacitaciones" class="px-4 px-md-0 mt-xl-15 pt-xl-15">
        <v-col cols="12" align="center" class="px-md-15">
          <h2 class="primary--text text-lg-h5 text-md-h6 font-weight-bold">
            !Capacítate todo lo que necesites!
          </h2>
          <v-divider class="my_divider my_yellow mt-3" />
        </v-col>
        <v-col
          cols="12"
          md="10"
          align="center"
          class="px-md-15 mb-10 py-10 offset-md-1"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
              class="d-flex justify-center flex-column mt-16 mt-sm-4"
            >
              <div class="position_relative mb-10">
                <div
                  class="my_soft_blue"
                  style="
                    height: 125%;
                    max-width: 200px;
                    min-width: 200px;
                    position: absolute;
                    top: -40px;
                    left: 50px;
                  "
                  data-aos="fade-down"
                  data-aos-delay="1000"
                ></div>
                <v-img
                  contain
                  max-height="420"
                  :src="'/img/aprendiendo.jpg'"
                  data-aos="fade-right"
                  data-aos-delay="400"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              class="d-flex justify-center flex-column mt-16 mt-sm-4"
            >
              <v-img
                contain
                max-height="120"
                :src="'/img/academia.jpg'"
                data-aos="fade"
                data-aos-delay="400"
              />
              <div class="pt-0 text-left">
                Nos preocupamos por ti, es por ello que hemos creado una
                plataforma para que tus colaboradores y tu empiecen hoy a
                formarse en el uso de mynube ERP, haz que tu empresa sea más
                dinámica, productiva y rentable, aprenderan a su ritmo en
                cualquier momento y lugar.
              </div>
              <div class="pt-10">
                <v-btn
                  large
                  color="my_battery_blue"
                  dark
                  elevation="0"
                  href="https://academia.mynube.com/"
                  target="_blank"
                >
                  Ir a la plataforma
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <!--          <calendario-capacitaciones />-->
        </v-col>
      </v-row>
      <v-row id="heading-6" class="px-xl-16 mx-xl-16 mb-1 mb-sm-16">
        <v-col
          cols="12"
          md="6"
          lg="5"
          align="center"
          class="d-flex pt-15 justify-center my_cyan pb-16"
          data-aos="fade-right"
          data-aos-delay="300"
        >
          <form-contrato />
        </v-col>
        <v-col
          cols="12"
          md="5"
          lg="5"
          class="offset-md-1 offset-lg-2 d-flex justify-center flex-column mt-16 mt-sm-4"
        >
          <div class="position_relative mb-10">
            <div
              class="my_yellow"
              style="
                height: 125%;
                max-width: 200px;
                min-width: 200px;
                position: absolute;
                top: -40px;
                right: 50px;
              "
              data-aos="fade-down"
              data-aos-delay="1000"
            ></div>
            <v-img
              contain
              max-height="320"
              :src="'/img/contrato.jpg'"
              data-aos="fade-down"
              data-aos-delay="400"
            />
          </div>
          <!--          <v-card
            flat
            color="my_grey"
            class="mt-10 mt-md-16 pt-3 mb-15"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-card flat color="my_grey" class="">
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                        class="d-flex align-center justify-center"
                      >
                        <i
                          :style="
                            $vuetify.breakpoint.lgAndUp
                              ? 'font-size: 4.5rem'
                              : 'font-size: 3rem'
                          "
                          :class="['bx bx-file', 'primary&#45;&#45;text pt-5']"
                        ></i>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                        class="d-flex align-center justify-center support&#45;&#45;text font-weight-medium"
                      >
                        Puedes tomarte el tiempo de revisar las cláusulas,
                        condiciones y beneficios que hay en nuestro contrato.
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" class="pt-5">
                  <v-btn
                    block
                    color="primary"
                    class="px-10"
                    large
                    elevation="0"
                    @click="descargarContrato"
                  >
                    DESCARGAR CONTRATO
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>-->
        </v-col>
      </v-row>
      <!--      <v-row id="heading-7" class="px-xl-16 mx-xl-16">
        <v-col
          cols="12"
          md="6"
          lg="5"
          class="d-flex justify-center flex-column mt-16 mt-sm-4"
          order="2"
          order-md="1"
        >
          <div class="position_relative mb-5">
            <div
              class="my_yellow"
              style="
                height: 115%;
                max-width: 200px;
                min-width: 200px;
                position: absolute;
                top: -40px;
                right: 50px;
              "
              data-aos="fade-down"
              data-aos-delay="1000"
            ></div>
            <v-img
              contain
              max-height="400"
              :src="'/img/reforma10.png'"
              data-aos="fade-down"
              data-aos-delay="400"
            />
          </div>
          <v-card
            flat
            color="my_grey"
            class="mt-10 pt-3 mb-15"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <v-card-text>
              <v-row class="" no-gutters>
                <v-col cols="12">
                  <v-row class="primary&#45;&#45;text font-weight-bold">
                    <v-col cols="2" align="center">
                      <v-img
                        contain
                        max-height="20"
                        :src="'/img/iconos_contacto/icon_direccion.png'"
                      />
                    </v-col>
                    <v-col cols="10">
                      <p>
                        Ed. Reforma 10, Ofi. 903, Av. Reforma 9-55, Zona 10,
                        Guatemala.
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="primary&#45;&#45;text font-weight-bold">
                    <v-col cols="2" align="center">
                      <v-img
                        contain
                        max-height="20"
                        :src="'/img/iconos_contacto/icon_telefono.png'"
                      />
                    </v-col>
                    <v-col cols="10">
                      <p>PBX: (502) 2207-1984</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="primary&#45;&#45;text font-weight-bold">
                    <v-col cols="2" align="center">
                      <v-img
                        contain
                        max-height="20"
                        :src="'/img/iconos_contacto/icon_correo.png'"
                      />
                    </v-col>
                    <v-col cols="10">
                      <p>info@mynube.com</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="5"
          align="center"
          class="d-flex pt-8 my_battery_blue offset-lg-2"
          data-aos="fade-right"
          data-aos-delay="300"
          order="1"
          order-md="2"
        >
          <form-contactanos />
        </v-col>
      </v-row>-->
    </v-main>
    <v-footer padless color="primary">
      <v-card class="flex" flat tile color="primary">
        <v-card-text class="py-2 white--text text-center">
          &copy; {{ new Date().getFullYear() }} — <strong>mynube</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
/*
import FormContactanos from "../components/FormContactanos";
*/
import FormContrato from "../components/FormContrato";
/*import CalendarioCapacitaciones from "../components/CalendarioCapacitaciones";*/
import GaleriaEquipo from "../components/GaleriaEquipo";
import DialogPassword from "../components/DialogPassword";
import { downloadFile } from "../helpers";
export default {
  name: "Distribuidores",
  components: {
    /*
    FormContactanos,
*/
    FormContrato,
    GaleriaEquipo,
    /*CalendarioCapacitaciones,*/
    DialogPassword,
  },
  data: function () {
    return {
      dialog: false,
      password: "",
      items: [
        { title: "Nosotros", tarjet: "#heading-2" },
        { title: "Capacitaciones", tarjet: "#heading-capacitaciones" },
        { title: "Contactanos", tarjet: "#heading-6" },
        {
          title: "regresar",
          tarjet: "",
          nameRoute: "Partners",
          text: false,
          outlined: true,
          toPage: true,
        },
      ],
      logo: "/img/logo_mynube.png",
      app_bar_color: "white",
      menuColor: "support",
      isMobile: false,
      sidebar: false,
      widthLogo: 250,
      contactInfo: {
        name: "",
        email: "",
        reason: "",
        comment: "",
      },
      contactInfoDefault: {
        name: "",
        email: "",
        reason: "",
        comment: "",
      },
      contactFormRules: [(value) => !!value || "Llena este campo."],
      emailRules: [
        (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "Ingresa un correo valido.",
      ],
    };
  },
  computed: {},
  methods: {
    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.sidebar = false;
      }
      if (window.innerWidth <= 395) {
        this.widthLogo = 146;
      }
    },
    descargarContrato() {
      downloadFile("/dummy2.pdf", "dummy", "pdf");
    },
    handleDialog() {
      this.dialog = false;
    },
    removeBg() {
      var videoPresentacion = document.getElementById("video_presentacion");
      videoPresentacion.classList.toggle("bg-off-presentacion");
    },
    gotoHome() {
      this.$router.push({ name: "Home" });
    },
    handleSideBar(sidebarValue) {
      this.sidebar = sidebarValue;
    },
    handleNavigation(item) {
      if (item.toPage) {
        this.$router.push({ name: item.nameRoute });
      } else {
        this.$vuetify.goTo(item.tarjet);
        this.handleSideBar(!this.sidebar);
      }
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  mounted() {
    this.dialog = false;
    this.onResize();
    document.addEventListener("fullscreenchange", this.removeBg, false);
    document.addEventListener("mozfullscreenchange", this.removeBg, false);
    document.addEventListener("webkitfullscreenchange", this.removeBg, false);
  },
};
</script>

<style scoped>
@import url("https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css");
.bg-custom-grey {
  background-color: #f5f9fd;
}
.f-h {
  height: 93vh;
}

.bg-equipo {
  background-size: cover;
  background-position: center bottom;
  background-image: url("/img/equipo.jpg") !important;
}
.custom-blue-app-bar {
  background-color: #0495f1;
}

.my_divider {
  border: 4px solid;
  border-radius: 2px;
  max-width: 5rem;
}

.position_relative {
  position: relative;
}

.video_instuticional_back {
  height: 100%;
  position: absolute;
  bottom: -34px;
  left: 17px;
  width: 78%;
}

.presentacion_back {
  height: 100%;
  position: absolute;
  bottom: -34px;
  right: 17px;
  width: 78%;
}
.fl360-video-container {
  max-width: 100%;
  height: 300px;
  background-color: #2c2c2c;
}

.caracteristicas_list ul li {
  list-style: none;
  font-size: 20px;
}

.caracteristicas_list ul li:before {
  content: "·";
  font-size: 110px;
  vertical-align: middle;
  line-height: 20px;
  color: #10bfe6;
}

.linea_vid_inst {
  position: absolute;
  left: 160px;
  top: -84px;
}

.linea_vid_inst2 {
  position: absolute;
  right: 160px;
  bottom: -84px;
}

.vid-pres {
  border: 5px #333333 solid;
  max-width: 700px;
  position: absolute;
  top: 2.5rem;
}

.video_pres {
  width: 100%;
  height: 100%;
  max-width: 1034px;
  max-height: 543px;
  margin: 0 auto;
}

.video_pres .video_pres_cont {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.video_pres_cont .video-source {
  box-sizing: border-box;
  /*background: url(http://i.stack.imgur.com/zZNgk.png) center center no-repeat;*/
  background: url("/img/proyector.png") center center no-repeat;
  background-size: contain;
  padding: 4.1% 21.5% 32.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg-off-presentacion {
  background: black !important;
  padding: 0 !important;
}
#video_presentacion {
  width: 100%;
  height: 560px;
}

.video_demo {
  width: 100%;
  height: 100%;
  max-width: 1034px;
  max-height: 543px;
  margin: 0 auto;
}
.video_demo .video_demo_cont {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.video_demo_cont iframe {
  box-sizing: border-box;
  /*background: url(http://i.stack.imgur.com/zZNgk.png) center center no-repeat;*/
  background: url("/img/laptop.png") center center no-repeat;
  background-size: contain;
  padding: 9.5% 11% 13% 11.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .video_pres .video_pres_cont {
    padding-top: 0 !important;
    padding-bottom: 83%;
  }
  .video_pres_cont .video-source {
    box-sizing: border-box;
    background: none;
    background-size: auto;
    padding: 0;
    position: absolute;
  }

  #video_presentacion {
    width: 100%;
    height: 420px;
  }
  .linea_vid_inst {
    position: absolute;
    left: 10px;
    top: -84px;
  }

  .linea_vid_inst2 {
    position: absolute;
    right: 15px;
    bottom: -64px;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  #video_presentacion {
    width: 100%;
    height: 420px;
  }
  .linea_vid_inst {
    position: absolute;
    left: 10px;
    top: -84px;
  }

  .linea_vid_inst2 {
    position: absolute;
    right: 15px;
    bottom: -64px;
  }
}
</style>
