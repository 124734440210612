<template>
  <v-row>
    <v-col cols="12">
      <div class="text-left pt-5">
        <h1
          class="white--text text-lg-h4 text-md-h4 font-weight-bold pl-md-15 pl-4"
        >
          ¡Es tiempo de trabajar juntos!
        </h1>
        <v-divider class="my_divider my_yellow mt-3 ml-md-15 ml-4" />
      </div>
    </v-col>
    <v-col cols="12" align="left">
      <v-form ref="form" lazy-validation @submit.prevent="sendMessageContact">
        <v-card
          flat
          hover
          color="my_cyan"
          class="px-4 px-md-16 pb-5"
          max-width="800"
        >
          <v-row>
            <v-col cols="12" sm="12" class="">
              <h3 class="white--text">
                LLena los siguientes datos y un agente te contactará para que te
                des de alta como distribuidor.
              </h3>
            </v-col>
          </v-row>
          <v-row no-gutters class="pt-5 px-5">
            <v-col cols="12" sm="12" class="py-sm-0">
              <v-text-field
                background-color="white"
                v-model="info.name"
                clearable
                outlined
                placeholder="Ej. Luis Suaréz"
                dense
                label="Tu nombre y apellido"
                class="white--text"
                :rules="contactFormRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="py-sm-0">
              <v-text-field
                background-color="white"
                v-model="info.company"
                clearable
                outlined
                placeholder="Ej. mynube"
                dense
                label="Empresa donde trabajas"
                class="white--text"
                :rules="contactFormRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="py-sm-0">
              <v-text-field
                background-color="white"
                v-model="info.email"
                clearable
                outlined
                placeholder="Ej. distribuidor@empresa.com"
                label="Tu correo electrónico"
                dense
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="py-sm-0">
              <v-text-field
                background-color="white"
                v-model="info.phone"
                clearable
                outlined
                placeholder=""
                label="Numero de contacto"
                dense
                :rules="contactFormRules"
                @keydown.space.prevent
                @keypress="onlyNumber($event)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-1">
            <v-col cols="12" sm="12" class="py-sm-0">
              <v-btn
                :loading="loading"
                block
                color="my_yellow"
                class="float-right"
                large
                elevation="0"
                @click="sendMessageContact"
                >Enviar datos para contrato
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  name: "FormContrato",
  data: function () {
    return {
      loading: false,
      info: {
        name: "",
        company: "",
        email: "",
        phone: "",
      },
      infoDefault: {
        name: "",
        company: "",
        email: "",
        phone: "",
      },
      contactFormRules: [(value) => !!value || "Llena este campo."],
      emailRules: [
        (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "Ingresa un correo valido.",
      ],
    };
  },
  methods: {
    onlyNumber: function (evt) {
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async sendMessageContact() {
      if (!this.$refs.form.validate()) return;
      const token = await this.recaptcha();
      const url = `https://api.mynube.com/dev2/adm/web-contact-form`;
      this.loading = true;
      var config = {
        method: "post",
        url: url,
        headers: {},
        data: {
          token: token,
          formType: 2,
          formData: {
            ...this.info,
          },
        },
      };
      config.data.token = token;

      axios(config)
        .then((response) => {
          if (response) {
            this.$store.dispatch("alerts/setMessage", {
              message:
                "Tu solicitud de contacto con distribuidor ha sido enviada.",
              type: "success",
            });
            this.clearForm();
          }
        })
        .catch((error) => {
          if (error) {
            this.$store.dispatch("alerts/setMessage", {
              message:
                "Lo sentimos, ha ocurrido un error al enviar tu solicitud.",
              type: "error",
            });
            this.clearForm();
          }
        });
    },
    recaptcha() {
      return new Promise((resolve) => {
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("web_contract_form").then((token) => {
            resolve(token);
          });
        });
      });
    },
    clearForm() {
      this.$refs.form.resetValidation();
      this.info = { ...this.infoDefault };
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.my_divider {
  border: 4px solid;
  border-radius: 2px;
  max-width: 5rem;
}
</style>
