<template>
  <v-row no-gutters class="pb-md-16 pb-4" align="center" justify="center">
    <v-col cols="12" md="4" v-for="(item, i) in items" :key="i" class="pa-2">
      <v-card
        hover
        :max-width="$vuetify.breakpoint.mdAndUp ? 300 : ''"
        :max-height="$vuetify.breakpoint.mdAndUp ? 200 : ''"
        outlined
        class="pa-4"
        :class="item.color"
        data-aos="zoom-in"
        data-aos-delay="500"
      >
        <v-row>
          <v-col
            cols="12"
            class="pt-5 pt-md-5 pt-lg-5 pb-0 mt-5"
            align="center"
            justify="center"
          >
            <v-icon
              v-if="item.icon.includes('mdi')"
              color="white"
              class="v-icon-large"
            >
              {{ item.icon }}
            </v-icon>
            <i
              v-else
              style="font-size: 4.5rem"
              :class="[item.icon, 'white--text']"
            ></i>
          </v-col>
          <v-col
            cols="12"
            class="pt-3 mt-0 grey--text text--darken-3"
            align="center"
            justify="center"
          >
            <h3 class="font-weight-bold white--text">
              {{ item.title }}
            </h3>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Estrategias",
  data: function () {
    return {
      items: [
        {
          title: "Plan referidos",
          icon: "bx bx-user-voice",
          color: "my_soft_blue",
        },
        {
          title: "Plan distribuidor",
          icon: "bx bx-briefcase",
          color: "my_soft_blue",
        },
        {
          title: "Plan implementación",
          icon: "bx bx-server",
          color: "my_soft_blue",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-icon-large {
  font-size: 4.5rem !important;
}
</style>
