<template>
  <v-col cols="12">
    <v-row no-gutters>
      <v-col
        cols="12"
        class="bg_aws d-flex justify-center align-center flex-column py-16"
      >
        <h1 class="white--text text-h4 font-weight-medium px-10 px-md-1">
          Disponibilidad, escalabilidad y alta demanda
        </h1>
        <v-divider
          class="my_divider my_yellow ml-md-5 mt-5"
          data-aos="fade-down"
          data-aos-delay="200"
        />
        <div
          :style="$vuetify.breakpoint.xs ? 'width: 95%' : 'width: 55%'"
          class="px-10 pt-5 white--text font-weight-medium"
        >
          <h3>
            mynube trabaja todas sus aplicaciones usando la tecnología de Amazon
            web services.
          </h3>
          <h3 class="pt-5">
            La nube de AWS abarca 99 zonas de disponibilidad en 31 regiones
            geográficas de todo el mundo, lo que nos permite tener una
            disponibilidad del 98% en las aplicaciones.
          </h3>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="d-flex align-content-lg-space-between justify-center align-center flex-column flex-md-row pa-16 my-9"
      >
        <div class="py-10">
          <v-img
            :src="url_mynube_logo"
            contain
            max-width="450"
            class="px-10 px-md-0"
          />
        </div>
        <div class="px-16">
          <v-icon x-large color="my_battery_blue" class="big-plus"
            >mdi-plus</v-icon
          >
        </div>
        <div class="py-10">
          <v-img
            :src="url_aws_logo"
            contain
            max-width="220"
            class="px-5 px-md-0"
          />
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "AwsAd",
  data: function () {
    return {
      url_mynube_logo: "img/productos/logo_mynube.png",
      url_aws_logo: "img/productos/logo_aws.png",
    };
  },
};
</script>

<style scoped>
.bg_aws {
  background-size: cover;
  background-position: center bottom;
  background-image: url("/img/productos/bg_aws.png") !important;
}
.my_divider {
  border: 3px solid;
  border-radius: 2px;
  max-width: 5rem;
  width: 40px;
}
.big-plus {
  font-size: 84px !important;
}
</style>
