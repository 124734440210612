<template>
  <div>
    <v-card class="mx-auto" outlined>
      <v-card-text class="position-relative">
        <v-tooltip bottom v-if="item.info">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="position-absolute"
              style="top: 1rem; right: 1rem; z-index: 3"
              v-bind="attrs"
              v-on="on"
              @click="handleDialog(true)"
            >
              <v-icon>mdi-information-outline</v-icon>
            </div>
          </template>
          <span>Más información</span>
        </v-tooltip>
        <v-img :src="item.url" contain></v-img>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" max-width="300" persistent>
      <v-card>
        <v-card-title class="text-h6 primary--text">
          <v-row>
            <v-col>
              {{ item.name }}
              <div class="float-right">
                <v-btn icon @click="handleDialog(false)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div class="py-4">
            <v-row no-gutters class="my_grey rounded pa-5">
              <v-col cols="12" class="">
                <h4
                  class="primary--text font-weight-medium d-flex align-center"
                >
                  <v-icon color="primary">mdi-account-outline</v-icon>
                  <span class="ml-3"> Contacto: </span>
                </h4>
              </v-col>
              <v-col cols="12">
                <div class="pl-10">
                  {{ item.contacto }}
                </div>
              </v-col>
              <v-col cols="12" class="pt-5">
                <h4
                  class="primary--text font-weight-medium d-flex align-center"
                >
                  <v-icon color="primary">mdi-whatsapp</v-icon>
                  <span class="ml-3"> Telefono, Whatsapp: </span>
                </h4>
              </v-col>
              <v-col cols="12">
                <div class="pl-10">
                  {{ item.tel }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CardPartner",
  props: {
    item: Object,
  },
  data: function () {
    return {
      dialog: false,
    };
  },
  methods: {
    handleDialog(val) {
      this.dialog = val;
    },
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}
</style>
