<template>
  <v-col cols="12" class="">
    <v-row class="pt-10" v-for="(producto, index) in productos" :key="index">
      <v-col
        cols="12"
        sm="6"
        md="7"
        lg="7"
        class="px-16 py-10 d-flex align-center justify-center flex-column"
        :class="
          producto.reverse ? ($vuetify.breakpoint.md ? '' : 'offset-md-1') : ''
        "
        order="2"
        :order-md="producto.reverse ? 2 : 1"
      >
        <v-img :src="producto.url" contain />
        <div class="py-lg-10">
          <v-img :src="producto.logo" contain max-width="320" />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
        align="left"
        class="d-flex align-center justify-end mt-16 mt-sm-4"
        :class="
          producto.reverse
            ? $vuetify.breakpoint.md
              ? 'offset-md-1'
              : ''
            : 'offset-md-1'
        "
        order="1"
        :order-md="producto.reverse ? 1 : 2"
      >
        <v-row class="mb-15">
          <v-col cols="12" class="py-16">
            <div
              class="grey--text text--darken-2 position_relative"
              data-aos="fade-left"
              data-aos-delay="1000"
            >
              <v-card
                flat
                :color="producto.reverse ? 'primary' : 'my_grey'"
                class="px-10 pb-8"
                :min-width="$vuetify.breakpoint.mdAndDown ? 340 : 500"
              >
                <v-row class="pa-md-16">
                  <v-col cols="12">
                    <h1
                      class=""
                      :class="[
                        'pb-5 pt-10',
                        producto.reverse
                          ? 'my_battery_blue--text'
                          : 'primary--text',
                      ]"
                    >
                      {{ producto.title }}
                    </h1>
                    <v-divider
                      class="my_divider my_yellow ml-md-5"
                      data-aos="fade-down"
                      data-aos-delay="200"
                    />
                  </v-col>
                  <v-col cols="12">
                    <h4
                      :class="[
                        'font-weight-medium',
                        producto.reverse ? 'white--text' : 'support--text',
                      ]"
                    >
                      {{ producto.text }}
                    </h4>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      x-large
                      color="my_soft_blue"
                      dark
                      elevation="0"
                      @click="openVideo(producto)"
                    >
                      <v-icon large left>mdi-play-circle-outline </v-icon>
                      <span class="ml-4">muestrame como funciona</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="800" persistent>
      <v-card>
        <v-card-title class="text-h5 primary--text">
          <v-row>
            <v-col>
              {{ productoSeleccionado.title }}
              <div class="float-right">
                <v-btn icon @click="closeVideo">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <div CLASS="py-4">
            {{ productoSeleccionado.text }}
          </div>
          <div class="pa-0 ma-0">
            <iframe
              width="100%"
              height="500"
              :src="productoSeleccionado.vid"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  name: "ProductosMynube",
  data: function () {
    return {
      dialog: false,
      productos: [
        {
          title: "mynube ERP",
          text: "Es un sistema de gestión administrativa, donde podrás realizar desde una cotización hasta estados financieros.",
          url: "img/productos/erp.png",
          logo: "img/productos/logo_mynube.png",
          vid: "https://www.youtube.com/embed/euZeC9oSExQ?si=k-HLlGCVlzP2NvK_",
          reverse: false,
        },
        /*{
          title: "FELEC360",
          text: "FELEC360, pensado en aquellos clientes que necesitan solo facturar con una interfaz amigable y con diversas plantillas para apoyar la imagen de las facturas de los clientes.",
          url: "img/productos/erp.png",
          logo: "img/productos/logo_felec360.png",
          reverse: true,
        },
        {
          title: "mynube\n" + "Contabilidad",
          text: "mynube contabilidad, diseñado especialmente para firmas de contadores o contadores independientes, donde podrás llevar la contabilidad fiscal.",
          url: "img/productos/erp.png",
          logo: "img/productos/logo_mynube.png",
          reverse: false,
        },
        {
          title: "mynube\n" + "Planillas",
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and.",
          url: "img/productos/erp.png",
          logo: "img/productos/logo_mynube.png",
          reverse: true,
        },
        {
          title: "mynube \n" + "Transformación",
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and.",
          url: "img/productos/erp.png",
          logo: "img/productos/logo_mynube.png",
          reverse: false,
        },*/
      ],
      productoSeleccionado: {},
    };
  },
  methods: {
    handleDialog(val) {
      this.dialog = val;
    },
    closeVideo() {
      this.productoSeleccionado = Object.assign({}, {});
      this.handleDialog(false);
    },
    openVideo(item) {
      this.productoSeleccionado = Object.assign({}, item);
      this.handleDialog(true);
    },
  },
};
</script>

<style scoped>
.my-producto-container {
  max-width: 600px;
}
.my_divider {
  border: 3px solid;
  border-radius: 2px;
  max-width: 5rem;
}
</style>
