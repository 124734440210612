var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"bg_aws d-flex justify-center align-center flex-column py-16",style:(_vm.$vuetify.breakpoint.xlOnly
          ? 'height: 600px'
          : _vm.$vuetify.breakpoint.lgAndUp
          ? 'height: 500px'
          : ''),attrs:{"cols":"12"}},[_c('h1',{staticClass:"white--text pt-lg-10 text-h5 text-md-h5 font-weight-medium text-center text-md-left"},[_vm._v(" ¿Te gustaría convertirte en un "),_c('span',{staticClass:"my_yellow--text"},[_vm._v("partner")]),_vm._v(" de mynube? ")]),_c('v-divider',{staticClass:"my_divider my_yellow ml-md-5 mt-5",attrs:{"data-aos":"fade-down","data-aos-delay":"200"}}),_c('div',{staticClass:"px-10 pt-5 white--text font-weight-medium pb-10",style:(_vm.$vuetify.breakpoint.lgAndUp
            ? 'width: 55%'
            : _vm.$vuetify.breakpoint.xsOnly
            ? 'width: 100%'
            : 'width: 80%')},[_c('h3',[_vm._v(" Trabajamos de la mano día con día con más de 5,000 personas diariamente, ayudando a llevar un mejor control sobre los procesos de su empresa, "),_c('span',{staticClass:"my_yellow--text"},[_vm._v("únete hoy")]),_vm._v(" a este esfuerzo con la estrategia que más se alinea con tus objetivos. ")])]),_c('v-btn',{attrs:{"large":"","color":"my_battery_blue","dark":"","elevation":"0"},on:{"click":_vm.gotoConvertirmeEnPartner}},[_vm._v(" convertirme en partner ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }