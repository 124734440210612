import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Distribuidores from "../views/Distribuidores";
import Partners from "../views/Partners/Partners.vue";
import AcuerdosDeServicio from "../views/AcuerdosDeServicio";
import NuestroProductos from "../views/NuestroProductos.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/nuestros-productos",
    name: "NuestrosProductos",
    component: NuestroProductos,
  },
  {
    path: "/partners",
    name: "Partners",
    component: Partners,
  },
  {
    path: "/conviertete-en-partner",
    name: "ConvertirPartners",
    component: Distribuidores,
  },
  {
    path: "/acuerdos-de-servicio",
    name: "acuerdosDeServicio",
    component: AcuerdosDeServicio,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
});

export default router;
