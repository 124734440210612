<template>
  <v-col cols="12">
    <v-row>
      <v-col
        cols="12"
        md="7"
        lg="6"
        align="center"
        class="d-flex align-center flex-column justify-center"
        order="2"
        order-md="1"
      >
        <div
          class="text-left pt-16 ml-md-12 ml-lg-16"
          :style="
            $vuetify.breakpoint.mdAndDown ? 'max-width: 90%' : 'max-width: 70%'
          "
        >
          <h2 class="primary--text text-lg-h3 text-md-h4 font-weight-bold">
            Conoce nuestros productos digitales
          </h2>
          <v-divider class="my_divider my_yellow mt-4" />
          <div
            class="text-left py-10"
            :style="
              $vuetify.breakpoint.xs ? 'max-width: 100%' : 'max-width: 75%'
            "
          >
            <p class="support--text font-weight-medium">
              mynube ha desarrollado diversos productos digitales para apoyarte
              según las necesidades de tu negocio.
            </p>
          </div>
          <v-btn
            large
            color="primary"
            elevation="0"
            @click="gotoProductos"
            :block="$vuetify.breakpoint.xs"
          >
            ver productos
          </v-btn>
        </div>
      </v-col>
      <v-col
        col="12"
        md="5"
        lg="6"
        class="d-flex align-center justify-end"
        order="1"
        order-md="2"
      >
        <div class="grey--text text--darken-2 position_relative">
          <v-img
            :src="url_mynube_cicle"
            contain
            :max-width="$vuetify.breakpoint.mdAndDown ? 350 : 450"
            class="mb-5"
          />
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
export default {
  name: "productosBanner",
  data: function () {
    return {
      url_mynube_cicle: "img/productos/mynube_circle.png",
    };
  },
  methods: {
    gotoProductos() {
      this.$router.push({ name: "NuestrosProductos" });
    },
  },
};
</script>

<style scoped>
.my_divider {
  border: 3px solid;
  border-radius: 2px;
  max-width: 5rem;
  width: 40px;
}
</style>
