<template>
  <v-row id="heading-5" class="pt-0 pb-10">
    <v-col
      cols="12"
      md="2"
      align="center"
      class="d-flex justify-end align-center"
    >
      <v-img
        contain
        max-height="130"
        :src="'/img/parcials/seccion_2/lineas_1.png'"
      />
    </v-col>
    <v-col cols="12" md="8" class="pt-5" align="center">
      <div class="pt-15">
        <h1 class="primary--text text-lg-h4 text-md-h5 font-weight-bold pt-15">
          Nuestro Equipo
        </h1>
        <v-divider class="my_divider my_yellow mt-3" />
        <div class="text-left pt-10 px-4 px-5 px-md-15">
          <p class="support--text font-weight-medium">
            El equipo de mynube es dinámico y creativo, contamos con personas
            capacitadas en distintas áreas de negocio, para brindarte el apoyo y
            soporte necesario para el crecimiento de tu negocio. Y en todo
            momento nuestro principal objetivo es darte la mejor atención y el
            mejor software para que tu negocio sea rentable y productivo.
          </p>
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="8"
      align="center"
      class="offset-md-2 d-md-flex justify-md-center"
    >
      <v-sheet elevation="0" class="px-5 px-md-2">
        <v-chip-group mandatory active-class="my_battery_blue white--text">
          <v-chip
            color="primary"
            v-for="tag in tags"
            :key="tag"
            @click="setFilter(tag)"
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </v-sheet>
    </v-col>
    <v-col
      cols="12"
      md="10"
      lg="8"
      align="center"
      class="offset-md-1 offset-lg-2 pt-10"
    >
      <v-row justify="center">
        <v-col cols="12">
          <transition-group
            class="empleados"
            name="empleados"
            style="max-height: 450px; overflow: auto"
          >
            <div
              v-for="empleado in itemsGalery"
              :key="empleado.nombre"
              class="empleado ma-5 ma-md-1 ma-lg-3"
            >
              <v-card
                :color="empleado.color ? empleado.color : randomColor()"
                :width="widthCard"
                :height="heightCard"
                class="empleado-image-wrapper"
              >
                <v-img
                  contain
                  max-height="300"
                  class="empleado-image"
                  :src="
                    empleado.imagen ? empleado.imagen : '/img/empleados/GC.png'
                  "
                />
              </v-card>
              <div class="text-left">
                <h4 class="pt-5 pb-0 primary--text">{{ empleado.nombre }}</h4>
                <small class="pt-0 support--text">{{ empleado.puesto }} </small>
              </div>
            </div>
          </transition-group>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GaleriaEquipo",
  data: function () {
    return {
      currentFilter: "Todos",
      tags: [
        "Todos",
        "Administración",
        "Ventas",
        "Atención al usuario",
        "Tecnología",
        "Innovación Tecnológica",
      ],
      empleadosItems: [
        {
          nombre: "Giovanni Castillo",
          puesto: "CEO",
          departamento: "Administración",
          imagen: "/img/empleados/GC.png",
          color: "my_battery_blue",
        },
        {
          nombre: "Jimmy Oliva",
          puesto: "Gerente Comercial",
          departamento: "Ventas",
          imagen: "/img/empleados/JO.png",
          color: "my_soft_blue",
        },
        {
          nombre: "Gustavo Alvarez",
          puesto: "Senior Database Manager",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/GAL.png",
          color: "my_cyan",
        },
        {
          nombre: "Edwin Herrera",
          puesto: "Chief Developer Web ",
          departamento: "Tecnología",
          imagen: "/img/empleados/EH.png",
          color: "primary",
        },
        {
          nombre: "Danilo Monzón",
          puesto: "Quality Assurement",
          departamento: "Tecnología",
          imagen: "/img/empleados/DM.png",
          color: "my_battery_blue",
        },
        {
          nombre: "Marbin Soto",
          puesto: "Contador General",
          departamento: "Administración",
          imagen: "/img/empleados/MS.png",
          color: "my_cyan",
        },
        {
          nombre: "Gustavo Acuña",
          puesto: "Asesor",
          departamento: "Administración",
          imagen: "/img/empleados/GA.png",
          color: "my_battery_blue",
        },
        {
          nombre: "Karina Paiz",
          puesto: "Coordinadora de Servicio al Cliente",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/KP.png",
          color: "my_soft_blue",
        },
        {
          nombre: "Georgina Alvarez",
          puesto: "Asistente de Gerencia",
          departamento: "Administración",
          imagen: "/img/empleados/GRA.png",
          color: "my_battery_blue",
        },
        {
          nombre: "Joseline Rodriguez",
          puesto: "Agente Asistencia al Usuario",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/JM.png",
          color: "my_yellow",
        },
        {
          nombre: "Raúl Urbina",
          puesto: "Community Manager",
          departamento: "Ventas",
          imagen: "/img/empleados/RU.png",
          color: "my_cyan",
        },
        {
          nombre: "Gerber Noriega",
          puesto: "DBA Coordinator",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/GNOR.png",
          color: "my_battery_blue",
        },
        /* {
          nombre: "Wilmer De La Cruz",
          puesto: "DBA Asistent",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/WDLC.png",
          color: "my_battery_blue",
        },*/
        {
          nombre: "Sergio Figueros",
          puesto: "Senior Web Developer",
          departamento: "Tecnología",
          imagen: "/img/empleados/SF.png",
          color: "my_cyan",
        },
        {
          nombre: "Belter Vásquez",
          puesto: "Web Developer",
          departamento: "Tecnología",
          imagen: "/img/empleados/BV.png",
          color: "my_battery_blue",
        },
        {
          nombre: "José Castañeda",
          puesto: "Web Developer",
          departamento: "Tecnología",
          imagen: "/img/empleados/JC2.png",
          color: "my_soft_blue",
        },
        {
          nombre: "Kevin Moscoso",
          puesto: "Web Developer",
          departamento: "Tecnología",
          imagen: "/img/empleados/KM.png",
          color: "my_battery_blue",
        },
        {
          nombre: "Adrian Mejía",
          puesto: "Web Developer",
          departamento: "Tecnología",
          imagen: "/img/empleados/AM.png",
          color: "my_soft_blue",
        },
        {
          nombre: "Abner Ralak",
          puesto: "Web Developer",
          departamento: "Tecnología",
          imagen: "/img/empleados/ADRM.png",
          color: "my_yellow",
        },

        /*{
          nombre: "Erick David Alvarado",
          puesto: "Web Developer",
          departamento: "Tecnología",
          imagen: "/img/empleados/EAL2.png",
          color: "my_battery_blue",
        },*/

        /*{
          nombre: "Edy De León",
          puesto: "Web Developer",
          departamento: "Tecnología",
          imagen: "/img/empleados/EDL.png",
          color: "my_battery_blue",
        },
        {
          nombre: "Carlos Samayoa",
          puesto: "Web Developer",
          departamento: "Tecnología",
          imagen: "/img/empleados/CS.png",
          color: "my_yellow",
        },
        {
          nombre: "Cristian de León",
          puesto: "Ejecutivo de Cuentas",
          departamento: "Ventas",
          imagen: "/img/empleados/CDL.png",
          color: "my_yellow",
        },
        {
          nombre: "Gian Carlo Garcia",
          puesto: "Ejecutivo de Cuentas",
          departamento: "Ventas",
          imagen: "/img/empleados/GCG.png",
          color: "my_soft_blue",
        },*/
        /*{
          nombre: "Mayté Gutierrez",
          puesto: "Ejecutiva de Cuentas",
          departamento: "Ventas",
          imagen: "/img/empleados/MG.png",
          color: "my_battery_blue",
        },*/
        /*{
          nombre: "Edy Barillas",
          puesto: "Ejecutivo de Cuentas",
          departamento: "Ventas",
          imagen: "/img/empleados/EBA.png",
          color: "my_yellow",
        },*/
        /* {
          nombre: "Hans Solano",
          puesto: "Agente de Soporte",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/HS.png",
          color: "my_battery_blue",
        },*/
        /* {
          nombre: "Julio García",
          puesto: "Agente de Soporte",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/JG.png",
          color: "primary",
        },*/
        /*{
          nombre: "Andreina Sáez",
          puesto: "Agente de Soporte",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/AS.png",
          color: "my_soft_blue",
        },*/

        /*{
          nombre: "Edgar Toledo",
          puesto: "Asistente Administrativo de Gerencia",
          departamento: "Administración",
          imagen: "/img/empleados/ET.png",
          color: "my_cyan",
        },*/
        /* {
          nombre: "Alexander Tamate",
          puesto: "Agente de Soporte",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/AT.png",
          color: "my_yellow",
        },*/

        /*{
          nombre: "Jonathan Toledo",
          puesto: "Agente de Soporte",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/JT.png",
          color: "my_battery_blue",
        },*/

        /*{
          nombre: "Elmar Arenales",
          puesto: "Agente de Soporte",
          departamento: "Atención al usuario",
          imagen: "/img/empleados/EA.png",
          color: "my_yellow",
        },*/
        /*{
          nombre: "Erik Flores",
          puesto: "Chief Development & Cloud Engineer",
          departamento: "Innovación Tecnológica",
          imagen: "/img/empleados/EF.png",
          color: "primary",
        },*/
        {
          nombre: "Bryan Castillo",
          puesto: "Backend Developer",
          departamento: "Innovación Tecnológica",
          imagen: "/img/empleados/BC.png",
          color: "my_battery_blue",
        },
        {
          nombre: "Elena Fajardo",
          puesto: "Cloud Development and Innovation Manager Assistant",
          departamento: "Innovación Tecnológica",
          imagen: "/img/empleados/ELF.png",
          color: "my_soft_blue",
        },
        {
          nombre: "Fernando Ordoñez",
          puesto: "Fullstack Developer",
          departamento: "Innovación Tecnológica",
          imagen: "/img/empleados/FO.png",
          color: "my_battery_blue",
        },
        {
          nombre: "Pablo Rodríguez",
          puesto: "Backend Developer",
          departamento: "Innovación Tecnológica",
          imagen: "/img/empleados/PR.png",
          color: "my_soft_blue",
        },
        {
          nombre: "Walter Hernández",
          puesto: "Frontend Developer & UI/UX-designer",
          departamento: "Innovación Tecnológica",
          imagen: "/img/empleados/WH2.png",
          color: "my_yellow",
        },
        /*{
          nombre: "Steven Sis",
          puesto: "FullStack Developer",
          departamento: "Innovación Tecnológica",
          imagen: "/img/empleados/SS.png",
          color: "my_cyan",
        },*/
        /* {
          nombre: "Daniel Peralta",
          puesto: "Frontend Developer",
          departamento: "Innovación Tecnológica",
          imagen: "/img/empleados/DP.png",
          color: "my_soft_blue",
        },*/
        /*{
          nombre: "Alejandro Godoy",
          puesto: " Mobile & Web Developer",
          departamento: "Innovación Tecnológica",
          imagen: "/img/empleados/AG.png",
          color: "my_battery_blue",
        },*/
      ],
    };
  },
  computed: {
    itemsGalery() {
      if (this.currentFilter === "Todos") {
        return this.empleadosItems;
      } else {
        return this.empleadosItems
          .slice()
          .filter((item) => item.departamento === this.currentFilter);
      }
    },
    heightCard() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          height = 300;
          break;
        case "sm":
          height = 300;
          break;
        case "md":
          height = 300;
          break;
        case "lg":
          height = 300;
          break;
        case "xl":
          height = 300;
          break;
      }
      return height;
    },
    widthCard() {
      let width = 0;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          width = 260;
          break;

        case "sm":
          width = 260;
          break;

        case "md":
          width = 260;
          break;

        case "lg":
          width = 260;
          break;

        case "xl":
          width = 260;
          break;
      }
      return width;
    },
  },
  methods: {
    setFilter(filter) {
      this.currentFilter = filter;
    },
    randomColor() {
      let colors = [
        "primary",
        "my_yellow",
        "my_battery_blue",
        "my_soft_blue",
        "my_cyan",
      ];
      return colors[Math.floor(Math.random() * 5)];
    },
  },
};
</script>

<style scoped>
.my_divider {
  border: 4px solid;
  border-radius: 2px;
  max-width: 5rem;
}

.projects {
  margin-bottom: 50px;
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projects-enter {
  transform: scale(0.5) translatey(-80px);
  opacity: 0;
}

.projects-leave-to {
  transform: translatey(30px);
  opacity: 0;
}

.projects-leave-active {
  position: absolute;
  z-index: -1;
}

.project {
  transition: all 0.35s ease-in-out;
  margin: 10px;
  box-shadow: 0px 2px 8px lightgrey;
  border-radius: 3px;
  width: 180px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-image-wrapper {
  position: relative;
}

.project-image {
  width: 100%;
  height: 150px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.empleados {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.empleados-enter {
  transform: scale(0.5) translatey(-80px);
  opacity: 0;
}

.empleados-leave-to {
  transform: translatey(30px);
  opacity: 0;
}

.empleados-leave-active {
  position: absolute;
  z-index: -1;
}

.empleado {
  transition: all 0.35s ease-in-out;
  border-radius: 3px;
  width: 250px;
  height: 380px;
  flex-direction: column;
}

.empleado-image-wrapper {
  position: relative;
}

.empleado-image {
  width: 100%;
  position: absolute;
}
</style>
