<template>
  <v-app>
    <v-app-bar app elevate-on-scroll :color="app_bar_color">
      <v-app-bar-nav-icon
        class="hidden-sm-and-up"
        @click="sidebar = !sidebar"
      />
      <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
      <v-img
        contain
        max-height="60"
        :max-width="widthLogo"
        :src="logo"
        position="left"
        @click="gotoHome"
      >
      </v-img>
      <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <div class="mr-5 mr-md-5 d-inline-flex hidden-xs-only">
        <v-btn
          v-for="item in items"
          :key="item.title"
          @click="handleNavigation(item)"
          :text="item.text"
          :outlined="item.outlined"
          :color="menuColor"
          class="hidden-xs-only"
        >
          <small class="custom-font">{{ item.title }} </small>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.xsOnly"
      v-model="sidebar"
      app
      dark
      color="my_battery_blue"
    >
      <v-list dense nav expand>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="handleNavigation(item)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="pt-0">
      <v-row id="hero_sec" class="f-h pb-0 bg-partners">
        <v-col
          cols="12"
          align="center"
          class="d-flex align-center flex-column justify-center"
        >
          <div
            class="text-left pt-16"
            :style="
              $vuetify.breakpoint.xs
                ? 'max-width: 90%'
                : $vuetify.breakpoint.lgAndUp
                ? 'max-width: 49%'
                : 'max-width: 69%'
            "
          >
            <h1 class="primary--text text-lg-h3 text-md-h4 font-weight-bold">
              Conviertete en
              <span class="my_battery_blue--text">partner</span> de mynube
            </h1>
            <v-divider class="my_divider my_yellow mt-4" />
            <div class="text-left py-10">
              <p class="support--text font-weight-medium">
                Forme parte de la nube de colaboración y trabajo en equipo
                brindando soluciones y servicios de forma sobresaliente a sus
                clientes, para que logren un gran control, alcance comercial,
                ventas y soporte para su negocio.
              </p>
            </div>
            <div class="d-flex justify-md-space-around flex-column flex-md-row">
              <v-btn
                large
                color="primary"
                elevation="0"
                @click="gotoConvertirmeEnPartner"
                class="my-10 my-md-0"
              >
                Convertirme en partner
              </v-btn>
              <v-btn
                large
                dark
                color="my_cyan"
                elevation="0"
                @click="$vuetify.goTo('#encuentra_sec')"
              >
                Buscar partner
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row id="beneficios_sec" class="py-16">
        <beneficios />
      </v-row>
      <v-row id="convertirme_sec">
        <partner-ad />
      </v-row>
      <v-row id="encuentra_sec" class="my_grey pt-0 mt-0 pb-16">
        <partners-disponibles />
      </v-row>
    </v-main>
    <v-footer padless color="primary">
      <v-card class="flex" flat tile color="primary">
        <v-card-text class="py-2 white--text text-center">
          &copy; {{ new Date().getFullYear() }} — <strong>mynube</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import Beneficios from "../../components/partners/Beneficios.vue";
import PartnerAd from "../../components/partners/PartnerAd.vue";
import PartnersDisponibles from "../../components/partners/PartnersDisponibles.vue";

export default {
  name: "Partners",
  components: { PartnersDisponibles, PartnerAd, Beneficios },
  data: function () {
    return {
      dialog: false,
      password: "",
      items: [
        {
          title: "Beneficios",
          tarjet: "#beneficios_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "Convertirme en partner",
          tarjet: "#convertirme_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "Encuentra un partner",
          tarjet: "#encuentra_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "regresar",
          tarjet: "",
          nameRoute: "Home",
          text: false,
          outlined: true,
          toPage: true,
        },
      ],
      logo: "/img/logo_mynube.png",
      app_bar_color: "transparent",
      menuColor: "support",
      isMobile: false,
      sidebar: false,
      widthLogo: 250,
      contactInfo: {
        name: "",
        email: "",
        reason: "",
        comment: "",
      },
      contactInfoDefault: {
        name: "",
        email: "",
        reason: "",
        comment: "",
      },
      contactFormRules: [(value) => !!value || "Llena este campo."],
      emailRules: [
        (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "Ingresa un correo valido.",
      ],
    };
  },
  computed: {},
  methods: {
    handleSideBar(sidebarValue) {
      this.sidebar = sidebarValue;
    },
    handleNavigation(item) {
      if (item.toPage) {
        this.$router.push({ name: item.nameRoute });
      } else {
        this.$vuetify.goTo(item.tarjet);
        this.handleSideBar(!this.sidebar);
      }
    },
    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.sidebar = false;
      }
      if (window.innerWidth <= 395) {
        this.widthLogo = 146;
      }
    },
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.app_bar_color = "white";
        this.menuColor = "support";
      } else {
        this.app_bar_color = "transparent";
        this.menuColor = "support";
      }
    },
    handleDialog() {
      this.dialog = false;
    },
    removeBg() {
      var videoPresentacion = document.getElementById("video_presentacion");
      videoPresentacion.classList.toggle("bg-off-presentacion");
    },
    gotoConvertirmeEnPartner() {
      this.$router.push({ name: "ConvertirPartners" });
    },
    gotoHome() {
      this.$router.push({ name: "Home" });
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  mounted() {
    this.dialog = false;
    this.onResize();
    window.onscroll = () => {
      this.changeColor();
    };
    document.addEventListener("fullscreenchange", this.removeBg, false);
    document.addEventListener("mozfullscreenchange", this.removeBg, false);
    document.addEventListener("webkitfullscreenchange", this.removeBg, false);
  },
};
</script>

<style scoped>
@import url("https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css");
.bg-custom-grey {
  background-color: #f5f9fd;
}
.f-h {
  height: 93vh;
}

.bg-equipo {
  background-size: cover;
  background-position: center bottom;
  background-image: url("/img/equipo.jpg") !important;
}
.custom-blue-app-bar {
  background-color: #0495f1;
}

.my_divider {
  border: 4px solid;
  border-radius: 2px;
  max-width: 5rem;
}

.bg-partners {
  background-size: cover;
  background-position: center bottom;
  //background-image: url("/img/partners/hero_partner_b.png") !important;
  background-image: url("/img/partners/hero_partner.png") !important;
  height: 100vh;
}
</style>
