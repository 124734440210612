<template>
  <v-row class="pb-md-16 pb-4" :no-gutters="$vuetify.breakpoint.xsOnly">
    <v-col cols="12" md="6" v-for="(item, i) in items" :key="i" class="pa-2">
      <v-card
        rounded
        hover
        :max-height="$vuetify.breakpoint.mdAndUp ? 100 : ''"
        :min-height="$vuetify.breakpoint.xs ? 200 : ''"
        :max-width="$vuetify.breakpoint.lgAndUp ? 550 : 400"
        outlined
        class="pa-4"
        :class="item.color"
        data-aos="zoom-in"
        data-aos-delay="500"
      >
        <v-row>
          <v-col
            cols="12"
            md="2"
            lg="2"
            class="offset-lg-1 pb-0 d-flex align-center justify-center"
          >
            <v-icon
              v-if="item.icon.includes('mdi')"
              color="white"
              class="v-icon-large"
            >
              {{ item.icon }}
            </v-icon>
            <i
              v-else
              :style="
                $vuetify.breakpoint.xs
                  ? 'font-size: 4.5rem'
                  : 'font-size: 3.5rem'
              "
              :class="[item.icon, 'my_yellow--text pt-4 pt-md-1']"
            ></i>
          </v-col>
          <v-col
            cols="12"
            md="8"
            lg="7"
            class="pt-3 mt-0 grey--text text--darken-3"
            :align="$vuetify.breakpoint.xs ? 'center' : 'start'"
            justify="start"
          >
            <h3 class="font-weight-bold white--text pl-md-2 pl-lg-0">
              {{ item.title }}
              <span class="my_yellow--text">
                {{ ` ${item.distict_text}` }}
              </span>
            </h3>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Adicionales",
  data: function () {
    return {
      items: [
        {
          title: "Usar mynube ERP para tu negocio",
          distict_text: "gratis",
          icon: "bx bx-gift",
          color: "primary",
        },
        {
          title: "Usar mynube contabilidad para tus clientes",
          distict_text: "gratis",
          icon: "bx bx-gift",
          color: "primary",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-icon-large {
  font-size: 4.5rem !important;
}
</style>
