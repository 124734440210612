<template>
  <v-col cols="12">
    <v-row no-gutters>
      <v-col
        cols="12"
        class="bg_aws d-flex justify-center align-center flex-column py-16"
        :style="
          $vuetify.breakpoint.xlOnly
            ? 'height: 600px'
            : $vuetify.breakpoint.lgAndUp
            ? 'height: 500px'
            : ''
        "
      >
        <h1
          class="white--text pt-lg-10 text-h5 text-md-h5 font-weight-medium text-center text-md-left"
        >
          ¿Te gustaría convertirte en un
          <span class="my_yellow--text">partner</span> de mynube?
        </h1>
        <v-divider
          class="my_divider my_yellow ml-md-5 mt-5"
          data-aos="fade-down"
          data-aos-delay="200"
        />
        <div
          :style="
            $vuetify.breakpoint.lgAndUp
              ? 'width: 55%'
              : $vuetify.breakpoint.xsOnly
              ? 'width: 100%'
              : 'width: 80%'
          "
          class="px-10 pt-5 white--text font-weight-medium pb-10"
        >
          <h3>
            Trabajamos de la mano día con día con más de 5,000 personas
            diariamente, ayudando a llevar un mejor control sobre los procesos
            de su empresa,
            <span class="my_yellow--text">únete hoy</span> a este esfuerzo con
            la estrategia que más se alinea con tus objetivos.
          </h3>
        </div>
        <v-btn
          large
          color="my_battery_blue"
          dark
          elevation="0"
          @click="gotoConvertirmeEnPartner"
        >
          convertirme en partner
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "PartnerAd",
  data: function () {
    return {};
  },
  methods: {
    gotoConvertirmeEnPartner() {
      this.$router.push({ name: "ConvertirPartners" });
    },
  },
};
</script>

<style scoped>
.bg_aws {
  background-size: cover;
  background-position: center center;
  background-image: url("/img/partners/bg_convertirme_partner.png") !important;
}
.my_divider {
  border: 3px solid;
  border-radius: 2px;
  max-width: 5rem;
  width: 40px;
}
</style>
