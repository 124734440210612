<template>
  <v-row no-gutters class="pb-md-16 pb-4">
    <v-col cols="6" md="3" v-for="(item, i) in items" :key="i" class="pa-2">
      <v-card
        hover
        max-width="300"
        max-height="200"
        outlined
        class="pa-4"
        :class="item.color"
        data-aos="zoom-in"
        data-aos-delay="500"
      >
        <v-row>
          <v-col
            cols="12"
            class="pt-5 pt-md-5 pt-lg-5 pb-0"
            align="center"
            justify="center"
          >
            <v-icon
              v-if="item.icon.includes('mdi')"
              color="white"
              class="v-icon-large"
            >
              {{ item.icon }}
            </v-icon>
            <i
              v-else
              style="font-size: 2.5rem"
              :class="[item.icon, 'white--text']"
            ></i>
          </v-col>
          <v-col
            cols="12"
            class="pt-0 mt-0 grey--text text--darken-3"
            align="center"
            justify="center"
          >
            <small class="font-weight-bold white--text">
              {{ item.title }}
            </small>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ListadoSoluciones",
  data: function () {
    return {
      items: [
        {
          title: "Clientes",
          icon: "mdi-clipboard-account-outline",
          color: "my_soft_blue",
        },
        {
          title: "Ventas",
          icon: "bx bx-briefcase",
          color: "my_cyan",
        },
        {
          title: "Inventario",
          icon: "bx bx-package",
          color: "my_soft_blue",
        },
        {
          title: "Compras",
          icon: "bx bx-cart-alt",
          color: "my_cyan",
        },
        {
          title: "Proveedores",
          icon: "mdi-truck-outline",
          color: "my_cyan",
        },
        {
          title: "Finanzas",
          icon: "bx bx-bar-chart-alt-2",
          color: "my_soft_blue",
        },
        {
          title: "Contabilidad",
          icon: "bx bx-calculator",
          color: "my_cyan",
        },
        {
          title: "Recursos humanos",
          icon: "mdi-account-search-outline",
          color: "my_soft_blue",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-icon-large {
  font-size: 2.8rem !important;
}
</style>
