<template>
  <div>
    <v-carousel
      cycle
      :height="$vuetify.breakpoint.mdAndDown ? 400 : 500"
      show-arrows-on-hover
      :hide-delimiter-background="!!$vuetify.breakpoint.smAndUp"
    >
      <v-carousel-item v-for="(slide, i) in slides" :key="i">
        <v-sheet color="my_grey">
          <v-row class="fill-height" align="center" justify="center">
            <v-col
              :cols="slide.bigger ? 12 : 6"
              :md="slide.bigger ? 12 : 5"
              align="center"
              v-for="(item, j) in slide.clientes"
              :key="j"
            >
              <v-card
                v-if="slide.bigger"
                :height="heightCardBigger"
                :width="widthCardBigger"
              >
                <v-img :src="item.img" contain />
              </v-card>
              <v-card v-else :height="heightCard" :width="widthCard">
                <v-img :src="item.img" contain />
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "ListadoClientes",
  data: function () {
    return {
      slides: [
        {
          name: "slide-1",
          bigger: true,
          clientes: [{ name: "Bloque", img: "/img/clientes/clientes_1.jpg" }],
        },
        {
          name: "slide-2",
          bigger: true,
          clientes: [{ name: "Bloque2", img: "/img/clientes/clientes_2.jpg" }],
        },
        {
          name: "slide-3",
          bigger: true,
          clientes: [{ name: "Bloque3", img: "/img/clientes/clientes_3.jpg" }],
        },
        {
          name: "slide-4",
          bigger: false,
          clientes: [
            {
              name: "Academia Europea",
              img: "/img/clientes/academia_europea.jpg",
            },
            { name: "Bimbo", img: "/img/clientes/bimbo.jpg" },
            { name: "DHL", img: "/img/clientes/dhl.jpg" },
            { name: "Cafe Quetzal", img: "/img/clientes/cafe_quetzal.jpg" },
            /*{ name: "Cebollines", img: "/img/clientes/cebollines.jpg" },*/
          ],
        },
        {
          name: "slide-5",
          bigger: false,
          clientes: [
            { name: "Iglesia Verbo", img: "/img/clientes/iglesia_verbo.jpg" },
            { name: "INAB", img: "/img/clientes/inab.jpg" },
            { name: "Nutriansa", img: "/img/clientes/nutriansa.jpg" },
            {
              name: "Pan American Silver",
              img: "/img/clientes/pan_american.jpg",
            },
          ],
        },
        {
          name: "slide-6",
          bigger: false,
          clientes: [
            { name: "Plaza Madero", img: "/img/clientes/plaza_madero.jpg" },
            { name: "Pollo Landia", img: "/img/clientes/pollo_landia.jpg" },
            { name: "Helios", img: "/img/clientes/helios.jpg" },
            { name: "UNICAR", img: "/img/clientes/unicar.jpg" },
          ],
        },
        {
          name: "slide-7",
          bigger: true,
          clientes: [{ name: "Bloque4", img: "/img/clientes/clientes_4.jpg" }],
        },
        /*{
          name: "slide-8",
          bigger: false,
          clientes: [
            { name: "Colegio Belga", img: "/img/clientes/colegio_belga.jpg" },
            { name: "Colegio Loyola", img: "/img/clientes/colegio_loyola.jpg" },
            { name: "Más negocios", img: "/img/clientes/mas_negocios.jpg" },
          ],
        },*/
      ],
    };
  },
  computed: {
    heightCardBigger() {
      if (this.$vuetify.breakpoint.md) {
        return 390;
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 450;
      }
      return 300;
    },
    widthCardBigger() {
      if (this.$vuetify.breakpoint.md) {
        return 390;
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 450;
      }
      return 300;
    },

    heightCard() {
      if (this.$vuetify.breakpoint.md) {
        return 180;
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 200;
      }
      return 150;
    },
    widthCard() {
      if (this.$vuetify.breakpoint.md) {
        return 180;
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 200;
      }
      return 145;
    },
  },
};
</script>

<style scoped></style>
