<template>
  <v-col cols="12">
    <v-row no-gutters>
      <v-col cols="12" class="">
        <v-img
          contain
          max-height="80"
          :src="'/img/parcials/partners/linea_beneficio_1.png'"
          data-aos="fade-down"
          data-aos-delay="100"
          class="float-left pl-16"
        />
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="8"
        align="center"
        class="d-flex align-center flex-column justify-center"
      >
        <div
          class="text-left pt-16 ml-8 ml-md-0 ml-lg-16"
          :style="$vuetify.breakpoint.xs ? 'max-width: 100%' : 'max-width: 70%'"
        >
          <h1 class="primary--text text-lg-h3 text-md-h4 font-weight-bold">
            ¿Por qué hacerse Partner de mynube?
          </h1>
          <v-divider class="my_divider my_yellow mt-4" />
          <div
            class="text-left py-10"
            :style="
              $vuetify.breakpoint.xs ? 'max-width: 90%' : 'max-width: 75%'
            "
          >
            <p class="support--text font-weight-medium">
              El sistema mynube ERP es una herramienta que permite a los
              negocios tener un sistema de gestión administrativa, el cuál a
              través del registro de los documentos, genera la contabilidad y
              eso le permite tener un panorama financiero rápido y dinámico de
              lo que sucede en su negocio.
            </p>
            <p class="support--text font-weight-medium pt-5">
              Además, es una herramienta que está lista para los nuevos
              requerimientos de SAT.
            </p>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="5"
        lg="4"
        align="center"
        class="pr-lg-16 pb-16 pb-md-0"
      >
        <div>
          <v-img
            contain
            :max-height="$vuetify.breakpoint.lgAndUp ? 300 : 250"
            :src="'/img/partners/nube_1.png'"
            data-aos="fade-left"
            data-aos-delay="600"
            data-aos-duration="1200"
          />
        </div>
        <div class="position_relative mt-5 mb-16">
          <div class="my_soft_blue beneficio_back"></div>
          <v-card
            flat
            color="my_grey"
            class="px-10 pb-8"
            :max-width="
              $vuetify.breakpoint.smAndDown
                ? 290
                : $vuetify.breakpoint.md
                ? 350
                : 400
            "
          >
            <v-row>
              <v-col
                cols="12"
                class="text-left font-weight-medium support--text py-10 px-5"
              >
                <h4>
                  Al ser partner de mynube obtienes muchos beneficios, entre
                  ellos mejorar tu posicionamiento con tus clientes,
                  brindándoles una herramienta que les permite mejorar la
                  productividad.
                </h4>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="12" class="" align="center">
        <div
          class="pt-15"
          :style="
            $vuetify.breakpoint.lg
              ? 'max-width: 30%'
              : $vuetify.breakpoint.xs
              ? 'max-width: 90%'
              : 'max-width: 50%'
          "
        >
          <h3
            class="primary--text text-h5 text-lg-h4 text-md-h4 font-weight-bold pt-15"
            data-aos="fade-down"
            data-aos-delay="200"
          >
            Tenemos una estrategia para apoyar a crecer tu negocio
          </h3>
          <v-divider
            class="my_divider my_yellow mt-3"
            data-aos="fade-down"
            data-aos-delay="200"
          />
        </div>
      </v-col>
      <v-col cols="12" md="10" xl="10" class="offset-md-1 pt-15 offset-xl-2">
        <estrategias />
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="10"
        xl="10"
        class="offset-md-1 offset-lg-1 pt-5 d-md-flex jus offset-xl-2"
      >
        <adicionales />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Estrategias from "./Estrategias.vue";
import Adicionales from "./Adicionales.vue";

export default {
  name: "Beneficios",
  components: { Adicionales, Estrategias },
};
</script>

<style scoped>
.my_divider {
  border: 4px solid;
  border-radius: 2px;
  max-width: 5rem;
}

.position_relative {
  position: relative;
}

.beneficio_back {
  height: 100%;
  position: absolute;
  bottom: -34px;
  left: 2px;
  width: 90%;
}

@media (max-width: 600px) {
  .beneficio_back {
    height: 95%;
    position: absolute;
    bottom: -34px;
    left: 20px;
    width: 75%;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  .beneficio_back {
    height: 95%;
    position: absolute;
    bottom: -34px;
    left: -20px;
    width: 75%;
  }
}
@media (min-width: 1905px) {
  .beneficio_back {
    height: 95%;
    position: absolute;
    bottom: -34px;
    left: 40px;
    width: 70%;
  }
}
</style>
