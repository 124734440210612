var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"float-left pl-16",attrs:{"contain":"","max-height":"80","src":'/img/parcials/partners/linea_beneficio_1.png',"data-aos":"fade-down","data-aos-delay":"100"}})],1),_c('v-col',{staticClass:"d-flex align-center flex-column justify-center",attrs:{"cols":"12","md":"7","lg":"8","align":"center"}},[_c('div',{staticClass:"text-left pt-16 ml-8 ml-md-0 ml-lg-16",style:(_vm.$vuetify.breakpoint.xs ? 'max-width: 100%' : 'max-width: 70%')},[_c('h1',{staticClass:"primary--text text-lg-h3 text-md-h4 font-weight-bold"},[_vm._v(" ¿Por qué hacerse Partner de mynube? ")]),_c('v-divider',{staticClass:"my_divider my_yellow mt-4"}),_c('div',{staticClass:"text-left py-10",style:(_vm.$vuetify.breakpoint.xs ? 'max-width: 90%' : 'max-width: 75%')},[_c('p',{staticClass:"support--text font-weight-medium"},[_vm._v(" El sistema mynube ERP es una herramienta que permite a los negocios tener un sistema de gestión administrativa, el cuál a través del registro de los documentos, genera la contabilidad y eso le permite tener un panorama financiero rápido y dinámico de lo que sucede en su negocio. ")]),_c('p',{staticClass:"support--text font-weight-medium pt-5"},[_vm._v(" Además, es una herramienta que está lista para los nuevos requerimientos de SAT. ")])])],1)]),_c('v-col',{staticClass:"pr-lg-16 pb-16 pb-md-0",attrs:{"cols":"12","md":"5","lg":"4","align":"center"}},[_c('div',[_c('v-img',{attrs:{"contain":"","max-height":_vm.$vuetify.breakpoint.lgAndUp ? 300 : 250,"src":'/img/partners/nube_1.png',"data-aos":"fade-left","data-aos-delay":"600","data-aos-duration":"1200"}})],1),_c('div',{staticClass:"position_relative mt-5 mb-16"},[_c('div',{staticClass:"my_soft_blue beneficio_back"}),_c('v-card',{staticClass:"px-10 pb-8",attrs:{"flat":"","color":"my_grey","max-width":_vm.$vuetify.breakpoint.smAndDown
              ? 290
              : _vm.$vuetify.breakpoint.md
              ? 350
              : 400}},[_c('v-row',[_c('v-col',{staticClass:"text-left font-weight-medium support--text py-10 px-5",attrs:{"cols":"12"}},[_c('h4',[_vm._v(" Al ser partner de mynube obtienes muchos beneficios, entre ellos mejorar tu posicionamiento con tus clientes, brindándoles una herramienta que les permite mejorar la productividad. ")])])],1)],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"12","align":"center"}},[_c('div',{staticClass:"pt-15",style:(_vm.$vuetify.breakpoint.lg
            ? 'max-width: 30%'
            : _vm.$vuetify.breakpoint.xs
            ? 'max-width: 90%'
            : 'max-width: 50%')},[_c('h3',{staticClass:"primary--text text-h5 text-lg-h4 text-md-h4 font-weight-bold pt-15",attrs:{"data-aos":"fade-down","data-aos-delay":"200"}},[_vm._v(" Tenemos una estrategia para apoyar a crecer tu negocio ")]),_c('v-divider',{staticClass:"my_divider my_yellow mt-3",attrs:{"data-aos":"fade-down","data-aos-delay":"200"}})],1)]),_c('v-col',{staticClass:"offset-md-1 pt-15 offset-xl-2",attrs:{"cols":"12","md":"10","xl":"10"}},[_c('estrategias')],1),_c('v-col',{staticClass:"offset-md-1 offset-lg-1 pt-5 d-md-flex jus offset-xl-2",attrs:{"cols":"12","md":"10","lg":"10","xl":"10"}},[_c('adicionales')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }