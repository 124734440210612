<template>
  <v-app>
    <v-app-bar app elevate-on-scroll :color="app_bar_color">
      <v-app-bar-nav-icon
        class="hidden-sm-and-up"
        @click="sidebar = !sidebar"
      />
      <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
      <v-img
        contain
        max-height="60"
        :max-width="widthLogo"
        :src="logo"
        position="left"
        @click="gotoHome"
      >
      </v-img>
      <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <div class="mr-5 mr-md-5 d-inline-flex hidden-xs-only">
        <v-btn
          v-for="item in items"
          :key="item.title"
          @click="handleNavigation(item)"
          :text="item.text"
          :outlined="item.outlined"
          :color="menuColor"
          class="hidden-xs-only"
          :class="item.outlined ? 'ml-5' : ''"
        >
          <small class="custom-font">{{ item.title }} </small>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.xsOnly"
      v-model="sidebar"
      app
      dark
      color="my_battery_blue"
    >
      <v-list dense nav expand>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="handleNavigation(item)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-row id="hero_sec" class="px-xl-16 mx-xl-16 f-h pb-0">
        <v-col
          cols="12"
          md="6"
          align="center"
          class="d-flex align-center flex-column justify-center"
        >
          <div
            class="text-left pt-16 px-10 px-md-0"
            :style="
              $vuetify.breakpoint.xs ? 'max-width: 100%' : 'max-width: 70%'
            "
          >
            <h1 class="primary--text text-lg-h3 text-md-h4 font-weight-bold">
              Conoce nuestros productos digitales
            </h1>
            <v-divider class="my_divider my_yellow mt-4" />
            <div
              class="text-left py-10"
              :style="
                $vuetify.breakpoint.xs ? 'max-width: 90%' : 'max-width: 75%'
              "
            >
              <p class="support--text font-weight-medium">
                mynube ha desarrollado diversos productos digitales para
                apoyarte según las necesidades de tu negocio.
              </p>
            </div>
            <v-btn
              large
              :block="$vuetify.breakpoint.xs"
              color="primary"
              elevation="0"
              @click="$vuetify.goTo('#productos_sec')"
            >
              ver productos
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column justify-lg-end justify-md-center"
          align="center"
        >
          <div class="py-lg-10">
            <v-img
              :src="url_hero_productos"
              contain
              :max-width="$vuetify.breakpoint.lgAndUp ? 600 : 500"
            />
          </div>
        </v-col>
      </v-row>
      <v-row id="productos_sec" class="px-xl-16 mx-xl-16 mb-1">
        <productos-mynube />
      </v-row>
      <v-row id="aws_sec">
        <aws-ad />
      </v-row>
      <v-row id="soporte_sec">
        <chat-info />
      </v-row>
    </v-main>
    <v-footer padless color="primary">
      <v-card class="flex" flat tile color="primary">
        <v-card-text class="py-2 white--text text-center">
          &copy; {{ new Date().getFullYear() }} — <strong>mynube</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import ProductosMynube from "../components/productos/ProductosMynube.vue";
import AwsAd from "../components/productos/AwsAd.vue";
import ChatInfo from "../components/productos/chatInfo.vue";

export default {
  name: "NuestrosProductos",
  components: { ChatInfo, AwsAd, ProductosMynube },
  data: function () {
    return {
      dialog: false,
      password: "",
      items: [
        {
          title: "Productos",
          tarjet: "#productos_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "Escalabilidad",
          tarjet: "#aws_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "Soporte",
          tarjet: "#soporte_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "Regresar",
          tarjet: "",
          nameRoute: "Home",
          text: false,
          outlined: true,
          toPage: true,
        },
      ],
      logo: "/img/logo_mynube.png",
      app_bar_color: "white",
      menuColor: "support",
      isMobile: false,
      sidebar: false,
      widthLogo: 250,
      url_hero_productos: "img/productos/hero_productos.jpg",
    };
  },
  computed: {},
  methods: {
    handleSideBar(sidebarValue) {
      this.sidebar = sidebarValue;
    },
    handleNavigation(item) {
      if (item.toPage) {
        this.$router.push({ name: item.nameRoute });
      } else {
        this.$vuetify.goTo(item.tarjet);
        this.handleSideBar(!this.sidebar);
      }
    },
    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.sidebar = false;
      }
      if (window.innerWidth <= 395) {
        this.widthLogo = 146;
      }
    },
    gotoHome() {
      this.$router.push({ name: "Home" });
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  mounted() {
    this.dialog = false;
    this.onResize();
  },
};
</script>

<style scoped>
@import url("https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css");
.bg-custom-grey {
  background-color: #f5f9fd;
}
.f-h {
  height: 93vh;
}

.my_divider {
  border: 4px solid;
  border-radius: 2px;
  max-width: 5rem;
}

.presentacion_back {
  height: 100%;
  position: absolute;
  bottom: -34px;
  right: 17px;
  width: 78%;
}
.fl360-video-container {
  max-width: 100%;
  height: 300px;
  background-color: #2c2c2c;
}

.caracteristicas_list ul li {
  list-style: none;
  font-size: 20px;
}

.caracteristicas_list ul li:before {
  content: "·";
  font-size: 110px;
  vertical-align: middle;
  line-height: 20px;
  color: #10bfe6;
}

.linea_vid_inst {
  position: absolute;
  left: 160px;
  top: -84px;
}

.linea_vid_inst2 {
  position: absolute;
  right: 160px;
  bottom: -84px;
}

.vid-pres {
  border: 5px #333333 solid;
  max-width: 700px;
  position: absolute;
  top: 2.5rem;
}

.video_pres {
  width: 100%;
  height: 100%;
  max-width: 1034px;
  max-height: 543px;
  margin: 0 auto;
}

.video_pres .video_pres_cont {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.video_pres_cont .video-source {
  box-sizing: border-box;
  /*background: url(http://i.stack.imgur.com/zZNgk.png) center center no-repeat;*/
  background: url("/img/proyector.png") center center no-repeat;
  background-size: contain;
  padding: 4.1% 21.5% 32.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg-off-presentacion {
  background: black !important;
  padding: 0 !important;
}
#video_presentacion {
  width: 100%;
  height: 560px;
}

.video_demo {
  width: 100%;
  height: 100%;
  max-width: 1034px;
  max-height: 543px;
  margin: 0 auto;
}
.video_demo .video_demo_cont {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
.video_demo_cont iframe {
  box-sizing: border-box;
  /*background: url(http://i.stack.imgur.com/zZNgk.png) center center no-repeat;*/
  background: url("/img/laptop.png") center center no-repeat;
  background-size: contain;
  padding: 9.5% 11% 13% 11.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .video_pres .video_pres_cont {
    padding-top: 0 !important;
    padding-bottom: 83%;
  }
  .video_pres_cont .video-source {
    box-sizing: border-box;
    background: none;
    background-size: auto;
    padding: 0;
    position: absolute;
  }

  #video_presentacion {
    width: 100%;
    height: 420px;
  }
  .linea_vid_inst {
    position: absolute;
    left: 10px;
    top: -84px;
  }

  .linea_vid_inst2 {
    position: absolute;
    right: 15px;
    bottom: -64px;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  #video_presentacion {
    width: 100%;
    height: 420px;
  }
  .linea_vid_inst {
    position: absolute;
    left: 10px;
    top: -84px;
  }

  .linea_vid_inst2 {
    position: absolute;
    right: 15px;
    bottom: -64px;
  }
}
</style>
