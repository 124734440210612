<template>
  <v-app>
    <v-app-bar app elevate-on-scroll :color="app_bar_color">
      <v-app-bar-nav-icon
        class="hidden-sm-and-up"
        @click="sidebar = !sidebar"
      />
      <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
      <v-img
        contain
        max-height="60"
        :max-width="widthLogo"
        :src="logo"
        position="left"
        @click="$vuetify.goTo('#heading-1')"
      >
      </v-img>
      <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <div class="mr-5 mr-md-5 d-inline-flex hidden-xs-only">
        <v-btn
          v-for="item in items"
          :key="item.title"
          @click="handleNavigation(item)"
          :text="item.text"
          :outlined="item.outlined"
          :color="menuColor"
          class="hidden-xs-only"
        >
          <small class="custom-font">{{ item.title }} </small>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.xsOnly"
      v-model="sidebar"
      app
      dark
      color="my_battery_blue"
    >
      <v-list dense nav expand>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="handleNavigation(item)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="pb-2">
      <v-row id="hero_sec" class="f-h bg-heading-1 pb-0">
        <v-col
          cols="12"
          md="6"
          align="center"
          class="d-flex align-center justify-center"
        >
          <div
            class="text-left"
            :style="
              $vuetify.breakpoint.xs ? 'max-width: 90%' : 'max-width: 70%'
            "
            data-aos="fade-down"
            data-aos-delay="200"
          >
            <h1
              class="white--text text-lg-h3 text-md-h4 font-weight-bold pb-md-16 pt-16 pt-md-1"
            >
              ¡Gestiona tu empresa desde la nube!
            </h1>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="grow d-flex align-end justify-end pb-0"
          align="right"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <v-img
            contain
            :max-height="$vuetify.breakpoint.xl ? 825 : 655"
            :src="'/img/edificio.png'"
          />
        </v-col>
        <div class="banner">
          <div class="clouds">
            <img src="/img/clouds/cloud1.png" alt="nube" style="--i: 1" />
            <img src="/img/clouds/cloud2.png" alt="nube2" style="--i: 2" />
            <img src="/img/clouds/cloud3.png" alt="nube3" style="--i: 3" />
            <img src="/img/clouds/cloud4.png" alt="nube4" style="--i: 4" />
            <img src="/img/clouds/cloud5.png" alt="nube5" style="--i: 5" />
          </div>
        </div>
      </v-row>
      <v-row id="soluciones_sec" class="pt-0">
        <v-col
          cols="12"
          md="2"
          align="center"
          class="d-flex justify-end align-center"
        >
          <v-img
            contain
            max-height="130"
            :src="'/img/parcials/seccion_2/lineas_1.png'"
            data-aos="fade-down"
            data-aos-delay="100"
          />
        </v-col>
        <v-col cols="12" md="8" class="pt-5" align="center">
          <div class="pt-15">
            <h1
              class="primary--text text-lg-h4 text-md-h5 font-weight-bold pt-15"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              Soluciones que se acomodan a tu empresa
            </h1>
            <v-divider
              class="my_divider my_yellow mt-3"
              data-aos="fade-down"
              data-aos-delay="200"
            />
            <div class="text-left pt-10 px-4 px-lg-15 px-md-5">
              <p
                class="support--text font-weight-medium"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                El sistema mynube es un ERP que contiene distintos módulos que
                puedes utilizar según tus necesidades, podrás gestionar
                fácilmente tu empresa y llevar el control de tus inventarios,
                compras, ventas, contabilidad, etc. Todo esto funcionando en la
                nube de Amazon, con toda su seguridad, estabilidad y
                escalabilidad. A un costo accesible para todo tipo de negocio.
              </p>
            </div>
          </div>
          <listado-soluciones />
        </v-col>
      </v-row>
      <v-row id="productos_sec" class="pb-md-16 pb-5">
        <productos-banner />
      </v-row>
      <v-row id="clientes_sec" class="px-xl-16 mx-xl-16 mb-1">
        <v-col
          cols="12"
          md="5"
          lg="6"
          align="center"
          class="d-flex pt-15 justify-center bg-negocios pb-16"
          data-aos="fade-right"
          data-aos-delay="300"
        >
          <div
            class="text-left pt-15"
            :style="
              $vuetify.breakpoint.xs ? 'max-width: 90%' : 'max-width: 70%'
            "
          >
            <h1 class="white--text text-lg-h4 text-md-h5 font-weight-bold">
              Negocios que nos brindan su confianza
            </h1>
            <v-divider class="my_divider my_yellow mt-3" />
            <p class="white--text font-weight-medium text-lg-h5 py-15">
              En nuestro día a día nos esforzamos en brindarte la mejor atención
              y servicio a nuestros clientes, cada vez son más negocios que
              confían en nosotros.
            </p>
            <v-card
              flat
              class="px-4 pb-1 pt-4 mb-10"
              color="my_yellow"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <v-row>
                <v-col
                  cols="12"
                  align="center"
                  class="d-flex justify-center align-center"
                >
                  <p
                    class="white--text font-weight-medium text-lg-h5 text-center"
                  >
                    Estarás en buena compañía
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="7"
          lg="5"
          class="offset-lg-1 d-flex align-center justify-end mt-16 mt-sm-4"
        >
          <v-row class="mb-15">
            <v-col cols="12" class="py-16">
              <div
                class="grey--text text--darken-2 position_relative"
                data-aos="fade-left"
                data-aos-delay="1000"
              >
                <v-card
                  flat
                  color="my_grey"
                  class="px-10 pb-8"
                  :min-width="$vuetify.breakpoint.mdAndDown ? 340 : 500"
                >
                  <v-row>
                    <v-col cols="12" align="center">
                      <h2 class="pb-5 pt-10 primary--text">
                        ¡Nuestros clientes!
                      </h2>
                    </v-col>
                    <v-col cols="12">
                      <listado-clientes />
                    </v-col>
                  </v-row>
                </v-card>
                <div class="section_3_linea_1">
                  <v-img
                    contain
                    max-height="130"
                    :src="'img/parcials/seccion_3/lineas_negocio_1.png'"
                    data-aos="fade-down"
                    data-aos-delay="1300"
                  />
                </div>
                <div class="section_3_linea_2">
                  <v-img
                    contain
                    max-height="75"
                    :src="'img/parcials/seccion_3/lineas_negocio_2.png'"
                    data-aos="fade-down"
                    data-aos-delay="300"
                  />
                </div>
                <div class="section_3_linea_3">
                  <v-img
                    contain
                    max-height="120"
                    :src="'img/parcials/seccion_3/lineas_negocio_3.png'"
                    data-aos="fade-down"
                    data-aos-delay="500"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" class="" align="center">
          <div class="pt-15">
            <h3
              class="primary--text text-h4 font-weight-bold pt-15"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              Testimonios
            </h3>
            <v-divider
              class="my_divider my_yellow mt-3"
              data-aos="fade-down"
              data-aos-delay="200"
            />
          </div>
        </v-col>
        <testimonios-video />
      </v-row>
      <v-row align="center" justify="center">
        <v-col
          cols="12"
          md="11"
          lg="8"
          class="pt-5 px-md-16 px-lg-0"
          align="center"
        >
          <listado-diferenciadores />
        </v-col>
      </v-row>
      <galeria-equipo id="conocenos_sec" />
      <v-row class="f-h">
        <v-col
          cols="12"
          md="7"
          align="center"
          class="d-flex align-center justify-center flex-column position_relative"
        >
          <div class="my-16 my-md-1">
            <v-img
              contain
              :max-width="$vuetify.breakpoint.xs ? 350 : 500"
              :src="'/img/logo_mynube.png'"
              data-aos="fade-up"
              data-aos-delay="600"
            />
          </div>
          <div class="section_4_linea_2" v-show="$vuetify.breakpoint.mdAndUp">
            <v-img
              contain
              max-height="120"
              :src="'img/parcials/seccion_4/linea_1.png'"
              data-aos="fade-down"
              data-aos-delay="400"
            />
          </div>
          <div class="section_4_linea_1" v-show="$vuetify.breakpoint.mdAndUp">
            <v-img
              contain
              max-height="120"
              :src="'img/parcials/seccion_4/linea_3.png'"
              data-aos="fade-down"
              data-aos-delay="500"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="pb-0 my_battery_blue d-flex flex-column justify-end"
          align="center"
          data-aos="fade-left"
          data-aos-delay="400"
        >
          <h1 class="white--text text-lg-h4 text-md-h5 font-weight-bold">
            Solidez y Confianza
          </h1>
          <v-divider
            class="my_divider my_yellow d-flex align-self-center px-10 mt-4"
          />
          <div class="pb-15">
            <div class="text-left pt-10 px-4 px-md-15">
              <p class="white--text font-weight-medium">
                Desde el año 2008 nuestro ERP mynube ha estado evolucionando
                conjuntamente con los avances de la tecnología, para brindarte
                un sistema muy amigable, fácil de implementar en tu empresa y
                con todo lo que necesitas para administrar tu empresa para
                hacerla más rentable y productiva. Continuamente estamos
                mejorando mynube y agregando nuevos módulos administrativos para
                cubrir todas las áreas de tu negocio para que tengas tu
                información integrada y puedas tomar las mejores decisiones de
                negocio.
              </p>
            </div>
          </div>
          <div class="pb-0 mb-0">
            <v-img contain max-height="200" :src="'/img/clouds/cloud2.png'" />
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" class="mt-16 pb-16">
        <v-col
          cols="12"
          md="6"
          lg="8"
          xl="6"
          order="2"
          order-md="1"
          align="center"
          class="pb-md-0 offset-lg-2 offset-xl-0"
          data-aos="fade-right"
          data-aos-delay="400"
        >
          <div class="position_relative mt-5">
            <div class="my_yellow mapa_back"></div>
            <v-card
              flat
              color="my_grey"
              class="px-10 pb-8"
              :max-width="$vuetify.breakpoint.smAndDown ? 290 : 350"
            >
              <v-row>
                <v-col cols="12" align="center">
                  <i
                    :style="
                      $vuetify.breakpoint.lgAndUp
                        ? 'font-size: 4.5rem'
                        : 'font-size: 3rem'
                    "
                    :class="['bx bx-trending-up', 'primary--text pt-5']"
                  ></i>
                  <h3 class="pt-4 primary--text">En constante crecimiento</h3>
                </v-col>
                <v-col cols="12" class="text-left">
                  <small>
                    Actualmente, de la mano de distribuidores regionales,
                    estamos expandiendo nuestro mercado a clientes de toda
                    Latinoamérica y El Caribe. Lo que nos permite tener un
                    equipo de trabajo más grande para mejorar nuestro soporte y
                    servicio al cliente, nuestra capacidad de implementar y
                    capacitar a nuestros usuarios y sobre todo mejorar todo
                    nuestro ERP para que cada vez sea más rápido, más seguro y
                    sobre todo más fácil de usar.
                  </small>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="6"
          xl="6"
          order="1"
          order-md="2"
          class="d-flex justify-end align-end pt-10"
        >
          <v-img contain max-height="600" :src="'img/america_caribe.png'" />
        </v-col>
      </v-row>
      <v-row id="contactanos_sec" class="px-xl-16 mx-xl-16">
        <v-col
          cols="12"
          md="6"
          lg="5"
          align="center"
          class="d-flex pt-8 my_battery_blue"
          data-aos="fade-right"
          data-aos-delay="300"
        >
          <form-contactanos />
        </v-col>
        <v-col
          cols="12"
          md="5"
          lg="5"
          class="offset-md-1 d-flex justify-center flex-column mt-16 mt-sm-4"
        >
          <div class="position_relative mb-5">
            <div
              class="my_yellow"
              style="
                height: 115%;
                max-width: 200px;
                min-width: 200px;
                position: absolute;
                top: -40px;
                right: 50px;
              "
              data-aos="fade-down"
              data-aos-delay="1000"
            ></div>
            <v-img
              contain
              max-height="400"
              :src="'/img/reforma10.png'"
              data-aos="fade-down"
              data-aos-delay="400"
            />
          </div>
          <v-card
            flat
            color="my_grey"
            class="mt-10 pt-3 mb-15"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <v-card-text>
              <v-row class="" no-gutters>
                <v-col cols="12">
                  <v-row class="primary--text font-weight-bold">
                    <v-col cols="2" align="center">
                      <v-img
                        contain
                        max-height="20"
                        :src="'/img/iconos_contacto/icon_direccion.png'"
                      />
                    </v-col>
                    <v-col cols="10">
                      <p>
                        Ed. Reforma 10, Ofi. 903, Av. Reforma 9-55, Zona 10,
                        Guatemala.
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="primary--text font-weight-bold">
                    <v-col cols="2" align="center">
                      <v-img
                        contain
                        max-height="20"
                        :src="'/img/iconos_contacto/icon_telefono.png'"
                      />
                    </v-col>
                    <v-col cols="10">
                      <p>PBX: (502) 2207-1984</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="primary--text font-weight-bold">
                    <v-col cols="2" align="center">
                      <v-img
                        contain
                        max-height="20"
                        :src="'/img/iconos_contacto/icon_soporte.png'"
                      />
                    </v-col>
                    <v-col cols="10">
                      <p>Soporte técnico: (502) 3128-5333</p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10"> </v-col>
                <v-col cols="12">
                  <v-row class="primary--text font-weight-bold">
                    <v-col cols="2" align="center">
                      <v-img
                        contain
                        max-height="20"
                        :src="'/img/iconos_contacto/icon_correo.png'"
                      />
                    </v-col>
                    <v-col cols="10">
                      <p>info@mynube.com</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <v-footer padless color="primary">
      <v-card class="flex" flat tile color="primary">
        <v-card-text class="py-2 white--text text-center">
          &copy; {{ new Date().getFullYear() }} — <strong>mynube</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import ListadoSoluciones from "../components/ListadoSoluciones";
import ListadoDiferenciadores from "../components/ListadoDiferenciadores";
import ListadoClientes from "../components/ListadoClientes";
import FormContactanos from "../components/FormContactanos";
import GaleriaEquipo from "../components/GaleriaEquipo";
import TestimoniosVideo from "../components/TestimoniosVideo";
import ProductosBanner from "../components/productos/productosBanner.vue";
export default {
  name: "Home",
  components: {
    ProductosBanner,
    ListadoSoluciones,
    ListadoDiferenciadores,
    ListadoClientes,
    FormContactanos,
    GaleriaEquipo,
    TestimoniosVideo,
  },
  data: function () {
    return {
      items: [
        {
          title: "Soluciones",
          tarjet: "#soluciones_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "Productos",
          tarjet: "#productos_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "Clientes",
          tarjet: "#clientes_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "Conocenos",
          tarjet: "#conocenos_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
        {
          title: "Partners",
          tarjet: "",
          nameRoute: "Partners",
          text: true,
          outlined: false,
          toPage: true,
        },
        {
          title: "Contactanos",
          tarjet: "#contactanos_sec",
          text: true,
          outlined: false,
          toPage: false,
        },
      ],
      logo: "/img/logo_mynube_white.png",
      app_bar_color: "custom_blue_app_bar",
      menuColor: "white",
      isMobile: false,
      sidebar: false,
      widthLogo: 250,
    };
  },
  methods: {
    handleSideBar(sidebarValue) {
      this.sidebar = sidebarValue;
    },
    handleNavigation(item) {
      if (item.toPage) {
        this.$router.push({ name: item.nameRoute });
      } else {
        this.$vuetify.goTo(item.tarjet);
        this.handleSideBar(!this.sidebar);
      }
    },
    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.sidebar = false;
      }
      if (window.innerWidth <= 395) {
        this.widthLogo = 146;
      }
    },
    changeColor() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        this.app_bar_color = "white";
        this.menuColor = "support";
        this.logo = "/img/logo_mynube.png";
      } else {
        this.app_bar_color = "custom_blue_app_bar";
        this.menuColor = "white";
        this.logo = "/img/logo_mynube_white.png";
      }
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();
    window.onscroll = () => {
      this.changeColor();
    };
  },
};
</script>
<style scoped>
@import url("https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css");
.bg-custom-grey {
  background-color: #f5f9fd;
}
.f-h {
  height: 93vh;
}
.custom-blue-app-bar {
  background-color: #0495f1;
}
.bg-heading-1 {
  background-size: cover;
  background: rgb(4, 149, 241);
  background: linear-gradient(
    180deg,
    rgba(4, 149, 241, 1) 0%,
    rgba(36, 59, 134, 1) 90%,
    rgb(66, 83, 140) 98%,
    rgba(233, 237, 250, 1) 100%
  );
}

.bg-negocios {
  background-size: cover;
  background-position: center bottom;
  background-image: url("/img/negocios-e.jpg") !important;
}
.my_divider {
  border: 4px solid;
  border-radius: 2px;
  max-width: 5rem;
}

.position_relative {
  position: relative;
}
.position_static {
  position: static !important;
}

.section_3_linea_1 {
  position: absolute;
  right: 40px;
  top: -84px;
}

.section_3_linea_2 {
  position: absolute;
  left: 102px;
  bottom: -47px;
}

.section_3_linea_3 {
  position: absolute;
  left: 54px;
  bottom: -100px;
}

.section_4_linea_1 {
  position: absolute;
  left: 200px;
  bottom: -50px;
}

.section_4_linea_2 {
  position: absolute;
  left: 240px;
  bottom: -3px;
}

.banner .clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.banner .clouds img {
  position: absolute;
  bottom: 0;
  max-width: 100%;
  max-height: 50%;
  animation: animate calc(18s * var(--i)) linear infinite;
}

.mapa_back {
  height: 100%;
  position: absolute;
  bottom: -34px;
  left: 40px;
  width: 65%;
}

@media (max-width: 600px) {
  .banner .clouds img {
    min-height: 30% !important;
  }
  .mapa_back {
    height: 100%;
    position: absolute;
    bottom: -22px;
    left: 28px;
    width: 75%;
  }
}

@keyframes animate {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}
</style>
