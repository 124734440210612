<template>
  <v-col cols="12" class="">
    <v-row no-gutters>
      <v-col
        cols="12"
        md="8"
        lg="6"
        class="offset-md-2 offset-lg-3 d-flex justify-center"
      >
        <div class="pt-15 text-center">
          <h1
            class="primary--text text-h5 text-lg-h4 text-md-h5 font-weight-bold pt-15 px-16 px-md-0"
            data-aos="fade-down"
            data-aos-delay="200"
          >
            Encuentre a un <span class="my_battery_blue--text">partner</span> de
            mynube
          </h1>
          <div class="text-left py-10 px-5 px-lg-2 px-md-5">
            <p
              class="support--text font-weight-medium text-sm"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              Cada uno de nuestro partners se enfoca en determinada área de la
              industria, es por ello que cada uno ofrece una amplia gama de
              soluciones y herramientas que ayudarán a crecer tu negocio de la
              mano con mynube.
            </p>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="8"
        lg="6"
        class="offset-md-2 offset-lg-3 d-flex justify-center pb-10 px-8"
      >
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              outlined
              dense
              clearable
              v-model="searchFilter"
              :hint="hint_text"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu bottom rounded offset-y nudge-width="50">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-chip
                  label
                  outlined
                  class="py-5 fixed-width-chip"
                  color="support"
                  v-bind="attrs"
                  v-on="{ ...menu }"
                >
                  <country-flag
                    v-if="pais.flag"
                    :country="pais.flag"
                    size="small"
                  />
                  <div class="pl-5 fixed-title-chip">
                    {{ pais.id == 0 ? "selecciona el país" : pais.name }}
                  </div>
                  <div v-if="$vuetify.breakpoint.mdAndUp" class="float-end">
                    <v-icon right small>mdi-chevron-down</v-icon>
                  </div>
                </v-chip>
              </template>
              <v-list class="">
                <v-list-item-group v-model="pais" color="blue">
                  <v-list-item
                    v-for="item in paises"
                    :key="item.id"
                    :value="item"
                  >
                    <v-list-item-title>
                      <country-flag :country="item.flag" size="small" />
                      <span class="ml-3">
                        {{ item.name }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="8"
        class="offset-md-1 offset-lg-2 px-8 px-md-1"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
            lg="4"
            v-for="(item, index) in filteredItems"
            :key="index"
          >
            <card-partner :item="item" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import CardPartner from "./CardPartner.vue";
import CountryFlag from "vue-country-flag";
export default {
  name: "PartnersDisponibles",
  components: { CardPartner, CountryFlag },
  data: function () {
    return {
      pais: { id: 0, name: "Todos", flag: "" },
      paises: [
        { id: 0, name: "Todos", flag: "" },
        { id: 1, name: "Guatemala", flag: "GT" },
        { id: 2, name: "Salvador", flag: "SV" },
      ],
      hint_text: "Busca por nombre",
      searchFilter: "",
      items: [
        {
          name: "Grajeda",
          url: "img/partners/disponibles/grajeda.png",
          pais: 1,
        },
        {
          name: "Intcomex",
          url: "img/partners/disponibles/intcomex.png",
          pais: 1,
        },
        {
          name: "Disagro",
          url: "img/partners/disponibles/disagro.png",
          pais: 1,
        },
        {
          name: "Megaprint",
          url: "img/partners/disponibles/megaprint.png",
          pais: 1,
        },
        {
          name: "NDA",
          url: "img/partners/disponibles/NDA.png",
          pais: 1,
        },
        {
          name: "infile",
          url: "img/partners/disponibles/infile.png",
          pais: 1,
        },
        {
          name: "A.B.H. Inversiones",
          url: "img/partners/disponibles/ABH.png",
          pais: 2,
          info: true,
          contacto: "Lic. Darío Torres García",
          tel: "+503 7089 9071",
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      let filtered = this.items;
      if (this.searchFilter) {
        filtered = filtered.filter(
          (item) =>
            item.name.toLowerCase().indexOf(this.searchFilter.toLowerCase()) >
            -1
        );
      }
      if (this.pais && this.pais.id !== 0) {
        filtered = filtered.filter((item) => item.pais === this.pais.id);
      }

      return filtered;
    },
  },
  methods: {},
};
</script>

<style scoped>
.my_divider {
  border: 3px solid;
  border-radius: 2px;
  max-width: 5rem;
  width: 40px;
}
.fixed-width-chip {
  width: 100%;
}
.fixed-title-chip {
  width: 150px;
}
</style>
