import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { Plugin } from "vue-fragment";
import i18n from "./i18n";
import "boxicons";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { VueReCaptcha } from "vue-recaptcha-v3";
// i18n.locale = "en";
Vue.config.productionTip = false;
Vue.use(Plugin);
Vue.use(VueToast);
Vue.use(VuePlyr, {
  plyr: {},
});
Vue.use(VueReCaptcha, { siteKey: "6LdkoQ0dAAAAABFX0Mo4j1VqHTduFvfXhxXsUZTw" });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  mounted() {
    AOS.init();
    AOS.init({
      once: true,
    });
  },
}).$mount("#app");
