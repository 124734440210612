<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
    style="overflow-x: hidden"
  >
    <v-card>
      <v-list>
        <div>
          <v-row no-gutters class="px-10 mx-md-0 pt-10 pt-md-1">
            <v-col cols="12">
              <v-img
                contain
                max-height="400"
                :src="'/img/privacidad.png'"
                data-aos="fade-down"
                data-aos-delay="250"
              />
            </v-col>
            <v-col cols="12" md="4" class="offset-md-4">
              <v-form
                ref="formPassword"
                lazy-validation
                @submit.prevent="validateToken"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <h3 class="my_soft_blue--text">¡Te tomara unos segundos!</h3>
                <p class="support--text">
                  Necesitamos que ingrese el token que te hemos enviado a tu
                  correo, para que puedas visualizar esta pagina.
                </p>
                <v-text-field
                  v-model="token"
                  clearable
                  outlined
                  label="Ingresa el token"
                  :rules="tokenRules"
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col cols="12" md="4" class="offset-md-4 pt-0">
              <v-btn
                dark
                elevation="0"
                block
                large
                color="my_soft_blue"
                @click="validateToken"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <v-icon left> mdi-lock-check-outline </v-icon>
                validar
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogPassword",
  data: function () {
    return {
      token: "",
      tokenRules: [(value) => !!value || "LLea este campo."],
      dialog: true,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    validateToken() {
      if (!this.$refs.formPassword.validate()) return;
      if (this.token === "MYNUBEDIST22") {
        this.close();
      } else {
        this.$store.dispatch("alerts/setMessage", {
          message: "Lo sentimos, el token ingresado no es valido!",
          type: "error",
        });
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
