<template>
  <v-row no-gutters class="pb-15">
    <v-col cols="12" md="8" class="offset-md-2" align="center">
      <div class="py-15">
        <h1
          class="primary--text text-lg-h4 text-md-h5 font-weight-bold pt-15"
          data-aos="fade-down"
          data-aos-delay="200"
        >
          Lo que nos diferencia
        </h1>
        <v-divider
          class="my_divider my_yellow mt-3"
          data-aos="fade-down"
          data-aos-delay="200"
        />
      </div>
    </v-col>
    <v-col cols="12" md="4" v-for="(item, i) in items" :key="i" class="pa-2">
      <v-card
        hover
        max-width="300"
        :min-height="$vuetify.breakpoint.md ? 370 : ''"
        outlined
        class="pa-5 my_grey"
        data-aos="zoom-in"
        data-aos-delay="500"
      >
        <v-row>
          <v-col
            cols="12"
            class="pt-5 pt-md-5 pt-lg-5 pb-0"
            align="center"
            justify="center"
          >
            <v-icon
              v-if="item.icon.includes('mdi')"
              color="support darken-1"
              class="v-icon-large"
            >
              {{ item.icon }}
            </v-icon>
            <i
              v-else
              style="font-size: 2.5rem"
              :class="[item.icon, 'support--text text--darken-1']"
            ></i>
          </v-col>
          <v-col cols="12" class="py-0 mt-0" align="center" justify="center">
            <small class="font-weight-bold support--text text--darken-1">
              {{ item.title }}
            </small>
          </v-col>
          <v-col cols="12" align="left" class="pt-0 pb-5 support--text px-2">
            <small class="">
              {{ item.text }}
            </small>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ListadoDiferenciadores",
  data: function () {
    return {
      items: [
        {
          title: "Muy fácil de usar",
          text: "Firmamos un contrato de confidencialidad con el Emisor FEL. mynube funciona completamente sobre Amazon Web Services por lo que contamos con un alto nivel de seguridad en la nube. mynube está siempre disponible desde cualquier lugar donde te encuentres y contamos con la capacidad técnica para atender miles de clientes.",
          icon: "bx bx-happy-beaming",
        },
        {
          title: "Funciona rápido",
          text: "Muchos clientes se sorprenden de lo rápido que funciona mynube sobre Internet, comparado con otros software que ellos han utilizado. Cuando iniciamos la programación de mynube en el año 2007, el ancho de banda era muy limitado, por lo que siempre usamos las técnicas más eficientes para que funcione lo más rápido posible.",
          icon: "mdi-clock-fast",
        },
        {
          title: "Soporte técnico",
          text: "Nuestro principal diferenciador es el soporte técnico que brindamos a nuestros clientes. Nos esmeramos en apoyar a nuestros clientes a lograr sus objetivos. Contamos con un sisema de tickets que nos permite administrar eficientemente todos tus requerimientos, para que estes contento utilizando nuestro software.",
          icon: "bx bx-support",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-icon-large {
  font-size: 2.8rem !important;
}
.my_divider {
  border: 4px solid;
  border-radius: 2px;
  max-width: 5rem;
}
</style>
