<template>
  <v-col cols="12">
    <v-row no-gutters>
      <v-col
        cols="12"
        md="5"
        align="center"
        class="d-flex pt-16 justify-center align-center bg-chat pb-16 f-h"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        <div
          class="text-left pt-16 mt-16 mt-md-0"
          :style="$vuetify.breakpoint.xs ? 'max-width: 90%' : 'max-width: 60%'"
        >
          <h1
            class="white--text text-lg-h4 text-md-h4 font-weight-bold pt-lg-16"
          >
            Soporte a tus clientes
          </h1>
          <v-divider class="my_divider my_yellow mt-3" />
          <p class="white--text font-weight-medium text-lg-h5 text-md-h6 py-15">
            mynube cuenta con un personal de calidad en el area de servicio al
            cliente, usamos diversas herramientas para resolver a brevedad los
            incidentes o preguntas que tengas tu o tus clientes.
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="7">
        <v-row class="fill-height">
          <v-col cols="12" md="7" class="">
            <v-card flat color="transparent" class="px-10 pr-md-0 pb-8">
              <v-row class="pt-10">
                <v-col cols="12">
                  <h1 class="" :class="['pb-5 pt-10 my_battery_blue--text']">
                    Utilizamos diversos canales de comunicación
                  </h1>
                  <v-divider
                    class="my_divider my_yellow ml-md-5"
                    data-aos="fade-down"
                    data-aos-delay="200"
                  />
                </v-col>
                <v-col cols="12" class="pb-10">
                  <h4 :class="['font-weight-medium support--text']">
                    Puedes escribirnos directamente desde nuestra aplicación,
                    llamarnos o escribirnos al telefono de soporte al cliente
                    <span class="my_soft_blue--text">(502) 3128-5333</span> o
                    enviarnos un correo a :
                    <span class="my_soft_blue--text">soporte@mynube.com</span>
                  </h4>
                </v-col>
                <v-col cols="12">
                  <v-row class="px-16">
                    <v-col
                      cols="6"
                      class="d-flex justify-center align-center pb-10"
                    >
                      <v-img :src="url_phone" contain max-width="60" />
                    </v-col>
                    <v-col
                      cols="6"
                      class="d-flex justify-center align-center pb-10"
                    >
                      <v-img :src="url_whatsapp" contain max-width="60" />
                    </v-col>
                    <v-col cols="6" class="d-flex justify-center align-center">
                      <v-img :src="url_gmail" contain max-width="60" />
                    </v-col>
                    <v-col cols="6" class="d-flex justify-center align-center">
                      <v-img :src="url_logo_intercom" contain max-width="170" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="5" class="d-flex justify-end align-end">
            <v-img :src="url_chat_img" contain max-width="350" class="mb-5" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "chatInfo",
  data: function () {
    return {
      url_chat_img: "img/chatInt.png",
      url_logo_intercom: "img/logo_intercom.png",
      url_phone: "img/phone.png",
      url_gmail: "img/gmail.png",
      url_whatsapp: "img/whatsapp.png",
    };
  },
};
</script>

<style scoped>
.bg-chat {
  background-size: cover;
  background-position: center bottom;
  background-image: url("/img/productos/bg_chat.jpg") !important;
}

.f-h {
  height: 93vh;
}

.my_divider {
  border: 3px solid;
  border-radius: 2px;
  max-width: 5rem;
  width: 40px;
}
</style>
