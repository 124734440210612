<template>
  <v-app>
    <v-app-bar app elevate-on-scroll :color="app_bar_color">
      <v-app-bar-nav-icon
        class="hidden-sm-and-up"
        @click="sidebar = !sidebar"
      />
      <v-img
        contain
        max-height="60"
        :max-width="widthLogo"
        :src="logo"
        position="left"
      >
      </v-img>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <div class="mr-5 mr-md-5 d-inline-flex hidden-xs-only">
        <v-btn text :color="menuColor" class="hidden-xs-only" @click="goHome">
          <small>Conoce nuestra web</small>
        </v-btn>
        <v-btn text :color="menuColor" class="hidden-xs-only" @click="gologin">
          <small>Volver al login</small>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="sidebar" app dark color="my_battery_blue">
      <v-list dense nav expand>
        <v-list-item @click="goHome">
          <v-list-item-content>
            <v-list-item-title>Conoce nuestra web</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="gologin">
          <v-list-item-content>
            <v-list-item-title>Volver al login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-row
        id="heading-1"
        class="px-xl-16 mx-xl-16 pb-0"
        :class="$vuetify.breakpoint.mdAndUp ? 'f-h' : ''"
      >
        <v-col
          cols="12"
          md="6"
          align="center"
          class="d-flex align-center flex-column justify-center"
          order="2"
          order-md="1"
        >
          <div
            class="text-left pt-16 pb-15 pb-md-1"
            :style="
              $vuetify.breakpoint.xs ? 'max-width: 90%' : 'max-width: 70%'
            "
          >
            <h1 class="primary--text text-lg-h3 text-md-h4 font-weight-bold">
              Estamos comprometidos con mantener un alto nivel de servicio para
              nuestros clientes.
            </h1>
            <v-divider class="my_divider my_yellow mt-4" />
            <div class="text-left py-10">
              <p class="support--text font-weight-medium">
                Te invitamos a leer el documento de acuerdos de niveles de
                servicio.
              </p>
            </div>
            <v-btn
              :large="$vuetify.breakpoint.mdAndUp"
              color="primary"
              elevation="0"
              @click="$vuetify.goTo('#heading-2')"
            >
              leer el acuerdo
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pb-0 bg-equipo d-flex flex-column justify-end"
          align="center"
          order="1"
          order-md="2"
        >
          <div class="pb-0 mb-0">
            <v-img contain max-height="200" :src="'/img/clouds/cloud2.png'" />
          </div>
        </v-col>
      </v-row>
      <v-row id="heading-2" class="pt-0 mb-16 py-16">
        <v-col
          cols="12"
          md="10"
          order="1"
          order-md="1"
          class="pt-5 offset-md-1 pb-md-16"
          align="center"
        >
          <div class="pt-15">
            <h1 class="primary--text text-lg-h4 text-md-h5 font-weight-bold">
              Acuerdos de Niveles de Servicio
            </h1>
            <v-divider class="my_divider my_yellow mt-3" />
          </div>
          <v-row class="pt-10" style="height: 100vh">
            <v-col cols="12" class="px-lg-10">
              <iframe
                src="/mynube-acuerdo-niveles-de-servicio.pdf"
                width="100%"
                height="100%"
              >
              </iframe>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="floating_container">
        <v-btn
          color="my_soft_blue"
          rounded
          elevation="5"
          block
          dark
          @click="gologin"
        >
          <v-icon> mdi-arrow-left </v-icon>
          volver al login
        </v-btn>
      </div>
    </v-main>
    <v-footer padless color="primary">
      <v-card class="flex" flat tile color="primary">
        <v-card-text class="py-2 white--text text-center">
          &copy; {{ new Date().getFullYear() }} — <strong>mynube</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "AcuerdosDeServicio",
  components: {},
  data: function () {
    return {
      dialog: false,
      password: "",
      items: [{ title: "regresar a login", goTo: "#heading-2" }],
      logo: "/img/logo_mynube.png",
      app_bar_color: "white",
      menuColor: "support",
      isMobile: false,
      sidebar: false,
      widthLogo: 250,
    };
  },
  computed: {},
  methods: {
    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.sidebar = false;
      }
      if (window.innerWidth <= 395) {
        this.widthLogo = 146;
      }
    },
    gologin() {
      window.location.href = "https://mynube.com/login";
    },
    goHome() {
      this.$router.push({ name: "Home" });
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();
  },
};
</script>

<style scoped>
.f-h {
  height: 93vh;
}
.my_divider {
  border: 4px solid;
  border-radius: 2px;
  max-width: 5rem;
}
.bg-equipo {
  background-size: cover;
  background-position: center bottom;
  background-image: url("/img/equipo.jpg") !important;
}

.floating_container {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 10px;
  right: 10%;
  z-index: 100;
}

@media (max-width: 600px) {
  .floating_container {
    right: 40%;
  }
  .bg-equipo {
    height: 50vh;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  .floating_container {
    right: 17%;
  }
}
</style>
