var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.productos),function(producto,index){return _c('v-row',{key:index,staticClass:"pt-10"},[_c('v-col',{staticClass:"px-16 py-10 d-flex align-center justify-center flex-column",class:producto.reverse ? (_vm.$vuetify.breakpoint.md ? '' : 'offset-md-1') : '',attrs:{"cols":"12","sm":"6","md":"7","lg":"7","order":"2","order-md":producto.reverse ? 2 : 1}},[_c('v-img',{attrs:{"src":producto.url,"contain":""}}),_c('div',{staticClass:"py-lg-10"},[_c('v-img',{attrs:{"src":producto.logo,"contain":"","max-width":"320"}})],1)],1),_c('v-col',{staticClass:"d-flex align-center justify-end mt-16 mt-sm-4",class:producto.reverse
          ? _vm.$vuetify.breakpoint.md
            ? 'offset-md-1'
            : ''
          : 'offset-md-1',attrs:{"cols":"12","sm":"6","md":"4","lg":"4","align":"left","order":"1","order-md":producto.reverse ? 1 : 2}},[_c('v-row',{staticClass:"mb-15"},[_c('v-col',{staticClass:"py-16",attrs:{"cols":"12"}},[_c('div',{staticClass:"grey--text text--darken-2 position_relative",attrs:{"data-aos":"fade-left","data-aos-delay":"1000"}},[_c('v-card',{staticClass:"px-10 pb-8",attrs:{"flat":"","color":producto.reverse ? 'primary' : 'my_grey',"min-width":_vm.$vuetify.breakpoint.mdAndDown ? 340 : 500}},[_c('v-row',{staticClass:"pa-md-16"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{class:[
                      'pb-5 pt-10',
                      producto.reverse
                        ? 'my_battery_blue--text'
                        : 'primary--text',
                    ]},[_vm._v(" "+_vm._s(producto.title)+" ")]),_c('v-divider',{staticClass:"my_divider my_yellow ml-md-5",attrs:{"data-aos":"fade-down","data-aos-delay":"200"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{class:[
                      'font-weight-medium',
                      producto.reverse ? 'white--text' : 'support--text',
                    ]},[_vm._v(" "+_vm._s(producto.text)+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","x-large":"","color":"my_soft_blue","dark":"","elevation":"0"},on:{"click":function($event){return _vm.openVideo(producto)}}},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v("mdi-play-circle-outline ")]),_c('span',{staticClass:"ml-4"},[_vm._v("muestrame como funciona")])],1)],1)],1)],1)],1)])],1)],1)],1)}),_c('v-dialog',{attrs:{"max-width":"800","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary--text"},[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.productoSeleccionado.title)+" "),_c('div',{staticClass:"float-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeVideo}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])],1)],1),_c('v-card-text',[_c('div',{attrs:{"CLASS":"py-4"}},[_vm._v(" "+_vm._s(_vm.productoSeleccionado.text)+" ")]),_c('div',{staticClass:"pa-0 ma-0"},[_c('iframe',{attrs:{"width":"100%","height":"500","src":_vm.productoSeleccionado.vid,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","referrerpolicy":"strict-origin-when-cross-origin","allowfullscreen":""}})])])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }