<template>
  <v-col cols="12" class="px-md-16">
    <v-row class="pt-10" v-for="(video, index) in videos" :key="index">
      <v-col cols="12" class="px-md-10 px-6 pt-5">
        <h3 class="pb-6 pl-sm-3 text-md-h5 font-weight-medium">
          <span class="pl-2 primary--text">{{ video.title }}</span>
        </h3>
        <v-divider
          class="my_divider my_yellow ml-md-5"
          data-aos="fade-down"
          data-aos-delay="200"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="12"
        lg="6"
        class="px-lg-10 px-6 pt-lg-10 pt-md-10 d-flex align-center justify-center"
        order="1"
        order-md=" 1"
        :order-lg="video.reverse ? 2 : 1"
      >
        <div
          class="my-video-container"
          :class="
            $vuetify.breakpoint.mdAndDown
              ? 'px-md-10 ml-md-6'
              : video.reverse
              ? ' mr-lg-15 mr-2'
              : ' ml-lg-15 ml-2'
          "
        >
          <div
            :data-aos="video.reverse ? 'fade-up-left' : 'fade-up-right'"
            data-aos-delay="200"
          >
            <vue-plyr>
              <div class="plyr__video-embed">
                <iframe
                  :src="video.url"
                  allowfullscreen
                  allowtransparency
                  allow="autoplay"
                ></iframe>
              </div>
            </vue-plyr>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="12"
        lg="6"
        align="left"
        class="d-flex align-center py-10 px-lg-15 px-2"
        :class="$vuetify.breakpoint.mdAndDown ? 'justify-center' : ''"
        order="2"
        order-md="2"
        :order-lg="video.reverse ? 1 : 2"
      >
        <div class="grey--text text--darken-2">
          <v-card
            flat
            color="my_grey"
            class="pa-10 pa-md-10 pa-lg-10"
            :max-width="$vuetify.breakpoint.mdAndDown ? 750 : 550"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <v-row>
              <v-col
                cols="12"
                class="font-weight-medium blue-grey--text text--darken-2"
              >
                <p>{{ video.text }}</p>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "TestimoniosVideo",
  data: function () {
    return {
      videos: [
        {
          title: "Café Quetzal",
          text:
            "Café Quetzal, una empresa Guatemalteca con más de 87 años, dedicados a la producción y comercialización de café tostado y molido; se han dedicado a satisfacer el gusto por el buen Café en un mercado exigente por su tradición cafetera. Tienen una participación en el mercado del 30% en su categoría." +
            "\n" +
            "Quetzal desde siempre el mejor Café de Guatemala.",
          url: "https://www.youtube.com/embed/Gq5MEfi5TNg",
          reverse: false,
        },
        {
          title: "Academia Europea",
          text:
            "Academia Europea es la academia de enseñanza de idiomas líder en el continente americano. Actualmente cuenta con más de 70 sucursales; estando presentes en 7 países, desde México hasta Costa Rica y República Dominicana, transformando a más de 1 millón de personas en bilingües y trilingües.\n" +
            "Les respaldan 50 años en el arte de la enseñanza de idiomas, son los únicos en el continente utilizando el patentado sistema IALS® considerado por los expertos como el mejor sistema para aprender otro idioma. Lo que los impulsa día a día a todos los que forman parte de Academia Europea es su pasión por enseñar idiomas que cambian las vidas de cada niño, joven, adulto o ejecutivo que pasa por sus aulas.",
          url: "https://www.youtube.com/embed/7x6-o3lxhDU",
          reverse: true,
        },
      ],
    };
  },
};
</script>

<style scoped>
.my-video-container {
  max-width: 600px;
  min-width: 600px;
}
.my_divider {
  border: 3px solid;
  border-radius: 2px;
  max-width: 5rem;
}

@media (max-width: 600px) {
  .my-video-container {
    max-width: 350px;
    min-width: 350px;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  .my-video-container {
    max-width: 800px;
    min-width: 800px;
  }
}
</style>
